import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DropDownWithDefaultSelect } from 'components/base/ActionsDropdown';
import { hasReactHookFormError } from 'components/base/ErrorFieldMessage';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { DISCOUNT_TRANSACTION_TYPE_DISPLAY } from 'config/CustomEnums';
import InputWithMutipleDropDown from './InputWithMutipleDropDown';
import LookupWithMutipleNumber from './LookupWithMutipleNumber';

const DiscountLoverSection = ({
  title,
  groupName,
  fieldBaseName
}) => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const typeFieldName = `${fieldBaseName}.type`;
  const type = watch(typeFieldName);

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setAction(value);
      },
    }));
  };

  return (
    <div className="d-flex flex-column">
      <InputWithMutipleDropDown
        fieldBaseName={fieldBaseName}
        title={title}
        hasPeriodDropdown
      />
      <CustomTitleLabel title="Amount of transaction with discount (optional)" />
      <div style={{ display: 'flex' }}>
        <Controller
          name={typeFieldName}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <DropDownWithDefaultSelect
              actions={dropDownOptions(
                [
                  DISCOUNT_TRANSACTION_TYPE_DISPLAY.NUMBER,
                  DISCOUNT_TRANSACTION_TYPE_DISPLAY.PERCENTAGE,
                ],
                (value) => {
                  setValue(field.name, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                },
              )}
              defaultValue={type}
              className={
                hasReactHookFormError(errors, field.name) ? 'error-field' : ''
              }
            />
          )}
        />

        <label className="content-campaign-related-to margin-right-10-percent">
          of transaction with discount
        </label>
      </div>

      <LookupWithMutipleNumber
        fieldBaseName={fieldBaseName}
        groupName={groupName}
        title={null}
        extraSuffix={type === DISCOUNT_TRANSACTION_TYPE_DISPLAY.PERCENTAGE ? '%' : null}
      />

    </div>
  );
}

export default DiscountLoverSection;
