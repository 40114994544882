import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  BackAndMultipleButtons,
  SaveButton,
  SaveTempButton,
} from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import BasePrompt from 'components/base/prompt/BasePrompt';
import RejectPrompt from 'components/campaign/campaignCreation/RejectPrompt';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import { MessageCreditCardContent } from 'components/message/MessageCredit';
import MessageOverviewTableSection from 'components/message/MessageOverviewTableSection';
import TestAccountPrompt from 'components/message/TestAccountPrompt';
import {
  ActionType,
  ApprovalStatus,
  MessageStepConfig,
} from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import { validate } from 'containers/engagement/message/CreateMessageValidate';

import { createAction, formatNumberWithCommas } from 'utils';
import { TimeFormater, formatDate } from 'utils/TimeFormatUtil';

import './CreateMessageStepFour.scss';

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  isSuperuser: state.users.isSuperuser,
  userPermissions: state.users.userPermissions,
  selectedChannels: state.createMessage.selectedChannels,
  estimateCredit: state.createMessage.estimateCredit || 0,
  realCreditTotalSpent: state.messageList.realCreditTotalSpent,
});

function CreateMessageStepFour({
  languages,
  isSuperuser,
  userPermissions,
  selectedChannels,
  realCreditTotalSpent,
  estimateCredit,
  setShowSentPrompt,
  setIsTestSend,
  setActionType,
}) {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { getValues, setError, clearErrors } = useFormContext();

  const message = getValues();
  message.displayChannels = message.channels?.toString();
  message.relatedCampaignName = message.relatedCampaign?.pk
    ? `[ID: ${message.relatedCampaign?.pk}] ${message.relatedCampaign?.name}`
    : '-';
  message.displayTargetCustomer = (message.targetCustomerGroup || [])
    .map((item) => item.name)
    .toString();
  message.displayTargetedSegments = (message.targetedSegments || [])
    .map((item) => item.name)
    .toString();
  message.deliveryDate = formatDate(
    message.scheduledDate,
    TimeFormater.dayMonthYearWeekTimeA,
  );
  message.displaySendToAll = message?.sendToAll ? 'Yes' : 'No';
  message.displayIsStartWithTemplate = message?.isStartWithTemplate
    ? 'Yes'
    : 'No';
  if (!message?.displayTemplateName) {
    message.displayTemplateName =
      message?.messageTemplate?.label || message?.messageTemplate?.value?.name;
  }

  if (location.pathname.includes('edit')) {
    message.relatedCampaignName = message.relatedCampaign?.pk
      ? `[ID: ${message.relatedCampaign?.pk}] ${message.relatedCampaign?.name}`
      : '-';
  }

  const [showRejectPrompt, setShowRejectPrompt] = useState(false);
  const [showTestPrompt, setShowTestPrompt] = useState(false);
  const [showTimeoutPrompt, setShowTimeoutPrompt] = useState(false);
  const [showApprovePrompt, setShowApprovePrompt] = useState(false);

  const approvalStatus = getValues('approvalStatus');
  let tempActionType = null;
  const hasPermission = (requires) => {
    if (isSuperuser) return true;
    if (userPermissions.includes(requires) || !requires) return true;

    return false;
  };

  const stepChangeAction = (isBack) => {
    let previewStep = 1;
    if (MessageStepConfig[1].show(selectedChannels)) {
      previewStep = previewStep + 1;
    }
    if (MessageStepConfig[2].show(selectedChannels)) {
      previewStep = previewStep + 1;
    }
    if (MessageStepConfig[3].show(selectedChannels)) {
      previewStep = previewStep + 1;
    }
    if (MessageStepConfig[4].show(selectedChannels)) {
      previewStep = previewStep + 1;
    }
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 5,
      isBack,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: isBack,
        step: previewStep,
        isValid,
      },
    });
  };

  const renderTabForLanguage = (language) => {
    return [
      <MessageOverviewTableSection message={message} language={language} />,
      <MembershipInfoCard
        title={'PROPERTIES'}
        data={message}
        fields={[
          [{ title: 'Start with a template', field: 'displayIsStartWithTemplate' }],
          [{ title: 'Template', field: 'displayTemplateName' }],
          [{ title: 'Name', field: 'name' }],
          [{ title: 'Message channel', field: 'displayChannels' }],
          [{ title: 'Related campaign', field: 'relatedCampaignName' }],
          [
            {
              title: 'Is it ‘Offer’ type message?',
              field: 'isDirectMarketing',
              isBoolean: true,
            },
          ],
          [{ title: 'Send to every customer (including non-member)', field: 'displaySendToAll' }],
          [{ title: 'Target customers', field: 'displayTargetCustomer' }],
          [{ title: 'Target segments', field: 'displayTargetedSegments' }],
          [{ title: 'Delivery Time', field: 'deliveryDate' }],
        ]}
      />,
    ];
  };

  const approvalButtonAction = () => {
    const values = getValues();
    console.log('values', saveActionType, values);
    if (saveActionType === ActionType.unpublish) {
      saveButtonAction(false, saveActionType);
      return;
    }
    if (
      (values.targetCustomerGroup?.length > 0 ||
        values.targetedSegments?.length > 0) &&
      !values?.sendToAll
    ) {
      saveButtonAction(false, saveActionType);
      return;
    }
    if (new Date(message.scheduledDate) < new Date()) {
      setShowTimeoutPrompt(true);
      return;
    }
    setShowApprovePrompt(true);
  };

  const saveButtonAction = (isSaveOnly, buttonAction = null) => {
    if (new Date(message.scheduledDate) < new Date()) {
      setShowTimeoutPrompt(true);
      return;
    }
    setActionType(buttonAction);
    if (
      buttonAction === ActionType.withdraw ||
      buttonAction === ActionType.unpublish
    ) {
      dispatch(
        createAction('createMessage/updateApprovalStatus')({
          data: getValues(),
          languages: languages,
          actionType: buttonAction,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(true);
          },
        }),
      );
    } else {
      dispatch(
        createAction('createMessage/createOrUpdateMessage')({
          values: getValues(),
          actionType: buttonAction,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(true);
          },
        }),
      );
    }
  };

  const getTempText = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        tempActionType = ActionType.update;
        return 'Update';
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.editPendingMessage)) {
          tempActionType = ActionType.update;
          return 'Update';
        }
        tempActionType = ActionType.withdraw;
        return 'Withdraw';
      case ApprovalStatus.published:
        tempActionType = ActionType.tempForPulished;
        return 'Update';
      default:
        tempActionType = ActionType.save;
        return 'Save as a draft';
    }
  };

  const hasTempPermission = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return hasPermission(PermissionCodes.editDraftMessage);
      case ApprovalStatus.pending:
        return (
          hasPermission(PermissionCodes.editPendingMessage) ||
          hasPermission(PermissionCodes.withdrawPendingMessage)
        );
      case ApprovalStatus.published:
        return hasPermission(PermissionCodes.createTempMessageFromPublish);
      default:
        return hasPermission(PermissionCodes.createDraftMessage);
    }
  };

  const primaryText = useMemo(() => {
    switch (approvalStatus) {
      case ApprovalStatus.published:
        return 'Unpublish';
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingMessage)) {
          return 'Approve';
        }
        return 'Pending for approval';
      default:
        return 'Submit for approval';
    }
  }, [approvalStatus]);

  const saveActionType = useMemo(() => {
    switch (approvalStatus) {
      case ApprovalStatus.published:
        return ActionType.unpublish;
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingMessage)) {
          return ActionType.approval;
        }
        return ActionType.pendingForApproval;
      default:
        return ActionType.submitForApproval;
    }
  }, [approvalStatus]);

  const hasSavePermission = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return hasPermission(PermissionCodes.pendingDraftMessage);
      case ApprovalStatus.pending:
        return (
          hasPermission(PermissionCodes.withdrawPendingMessage) ||
          hasPermission(PermissionCodes.publishPendingMessage)
        );
      case ApprovalStatus.published:
        return (
          hasPermission(PermissionCodes.unPublishMessageToPending) ||
          hasPermission(PermissionCodes.unPublishMessageToDraft)
        );
      default:
        return hasPermission(PermissionCodes.pendingDraftMessage);
    }
  };

  const getExtraPopContent = () => {
    const extraPopContent = [
      {
        content: 'Test Channel',
        action: () => {
          setShowTestPrompt(true);
        },
        requires: PermissionCodes.testMessage,
      },
      {
        content: getTempText(),
        action: () => saveButtonAction(true, tempActionType),
      },
    ];

    if (approvalStatus === ApprovalStatus.pending) {
      extraPopContent.push({
        content: 'Reject',
        action: () => {
          setShowRejectPrompt(true);
        },
        requires: PermissionCodes.publishPendingMessage,
      });
    }
    return extraPopContent;
  };

  const renderCurrentMessageCredit = () => {
    return (
      <div className="current-msg-credit-card">
        <div className="card-subtitle">
          {'Credit to-be-spent of this message'}
        </div>
        <div className="credit-to-be-spent">
          {formatNumberWithCommas(estimateCredit)}
        </div>
        <div>
          {
            'The value is an estimated value which is subject to varied at the time the message is sent out.'
          }
        </div>
      </div>
    );
  };

  const sections = [
    <div>
      <MessageCreditCardContent realCreditTotalSpent={realCreditTotalSpent} />
      {renderCurrentMessageCredit()}
    </div>,
  ];

  useEffect(() => {
    dispatch(
      createAction('createMessage/estimateMessageCredit')({
        channels: message?.channels,
        sendToAll: message?.sendToAll,
        segments: message?.targetedSegments?.map((item) => item?.pk),
        customerGroups: message?.targetCustomerGroup?.map((item) => item?.pk),
      }),
    );
    return () => {
      dispatch(
        createAction('createMessage/updateState')({
          estimateCredit: 0,
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentSections
        sections={sections}
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: renderTabForLanguage(item.code),
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <BackAndMultipleButtons
        backAction={() => {
          stepChangeAction(true);
        }}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={[
              <SaveTempButton
                text="Test Channel"
                action={() => {
                  setShowTestPrompt(true);
                }}
              />,
              hasTempPermission() ? (
                <SaveTempButton
                  text={getTempText()}
                  action={() => {
                    saveButtonAction(true, tempActionType);
                  }}
                />
              ) : null,
              approvalStatus === ApprovalStatus.pending &&
              hasPermission(PermissionCodes.publishPendingMessage) ? (
                <SaveTempButton
                  text="Reject"
                  action={() => {
                    setShowRejectPrompt(true);
                  }}
                />
              ) : null,
            ]}
            extraPopContent={getExtraPopContent()}
            primaryButton={
              hasSavePermission() ? (
                <SaveButton
                  text={primaryText}
                  action={() => approvalButtonAction()}
                  disabled={saveActionType === ActionType.pendingForApproval}
                />
              ) : null
            }
            primaryPopContent={{
              requires: PermissionCodes.addAdministratorGroup,
              action: () => saveButtonAction(false, saveActionType),
              content: 'Create Administrator group',
            }}
          />,
        ]}
      />
      <TestAccountPrompt
        show={showTestPrompt}
        onHide={() => setShowTestPrompt(false)}
        onConfirm={(selectedAccount) => {
          setShowTestPrompt(false);
          dispatch({
            type: 'createMessage/testMessageSend',
            payload: {
              isEdit: location.pathname.includes('edit'),
              values: getValues(),
              customer: selectedAccount,
              afterActions: () => {
                setIsTestSend(true);
                setShowSentPrompt(true);
              },
            },
          });
        }}
      />

      <RejectPrompt
        show={showRejectPrompt}
        onHide={() => setShowRejectPrompt(false)}
        onConfirm={(comment) => {
          setShowRejectPrompt(false);
          dispatch(
            createAction('createMessage/rejectMessage')({
              id: params.id,
              message: comment,
              afterAction: () => {
                const pathname = location.pathname.split('/')[1];
                history.push({
                  pathname: `/${pathname}`,
                });
              },
            }),
          );
        }}
      />

      <BasePrompt
        show={showTimeoutPrompt}
        closeAction={() => setShowTimeoutPrompt(false)}
        rightButton={{
          text: 'Reschedule',
          action: () => {
            setShowTimeoutPrompt(false);
            dispatch({
              type: 'createMessage/updateState',
              payload: {
                currentStep: 2,
              },
            });
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setShowTimeoutPrompt(false),
        }}
        title={'Schedule timeout'}
        description={
          'Your schecule time is expried, please update delivery time.'
        }
      />

      <BasePrompt
        show={showApprovePrompt}
        closeAction={() => setShowApprovePrompt(false)}
        disableClickBackground
        rightButton={{
          text: 'Confirm',
          action: () => {
            setShowApprovePrompt(false);
            saveButtonAction(false, saveActionType);
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setShowApprovePrompt(false),
        }}
        title={`${primaryText} message`}
        description={`Are you sure to ${primaryText} message?`}
      />
    </>
  );
}

export default connect(mapPropsToState)(CreateMessageStepFour);
