import React, { useState } from "react";
import { Collapse, Image } from 'react-bootstrap';

import ArrowDown from 'assets/images/import-dropdown-not-show-arrow.svg';
import ArrowUp from 'assets/images/import-dropdown-show-arrow.svg';
import AuthButton from "./AuthButton";

const AuthButtonWithDropdown = ({
  permissionRequires = "",
  title = "",
  options = [],
}) => {
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <AuthButton
      className={'btn-further import-button-with-dropdown'}
      action={() => setShowDropdown(!showDropdown)}
      requires={permissionRequires}
    >
      <div>{title}</div>
      <Image src={showDropdown ? ArrowUp : ArrowDown} />
      <Collapse in={showDropdown}>
        <div className="import-customer-dropdown">
          {options?.map(item=>
            <div
              onClick={item?.action || (() => { })}
            >
              {item?.title || ""}
            </div>
          )}
        </div>
      </Collapse>
    </AuthButton>
  ); 

}

export default AuthButtonWithDropdown;