import {
  TRANSACTION_EVENT_TYPE,
  TRANSACTION_RECORD_TYPE,
} from '../containers/record/records/TransactionList';
import {
  approveTransaction,
  deleteTransactions,
  getPurchasedItemAdditionalDataFields,
  getTransaction,
  getTransactionAdditionalDataFields,
  getTransactions,
  recallTransaction,
  rejectTransaction,
} from '../services/TransactionRecordsAPIHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  createAction,
  formatNumberWithCommas,
} from '../utils';
import {
  TimeFormater,
  formatDate,
  getCouponExpiryDate,
  getDisplayDate,
} from '../utils/TimeFormatUtil';
import { loading, apiWithResponseHandle } from './LoadingUtil';

const getInitialState = () => ({
  transactionList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    {
      displayName: 'Name (preferred name)',
      fieldName: 'name',
      linked: false,
      orderField: 'customerFirstName',
    },
    {
      displayName: 'Membership ID',
      fieldName: 'membershipId',
      orderField: 'membershipId',
    },
    {
      displayName: 'Creation Date',
      fieldName: 'displayCreationDate',
      orderField: 'creationDate',
    },
    {
      displayName: 'Transaction Date',
      fieldName: 'displayTransactionDate',
      orderField: 'date',
    },
    { displayName: 'Store', fieldName: 'displayStoreName', orderField: 'store' },
    { displayName: 'Gift card code', fieldName: 'giftCardCode' },
    {
      displayName: 'Eligible Total',
      fieldName: 'totalValue',
      orderField: 'totalValue',
    },
    { displayName: 'Paid amount', fieldName: 'paidAmount' },
    { displayName: 'Transaction Type', fieldName: 'transactionDisplayType' },
    { displayName: 'Online Event Type', fieldName: 'onlineEventType' },
    { displayName: 'Offline Event Type', fieldName: 'displayOfflineEventType' },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageTransactionList: [],
  selectedTransaction: {},
  transactionAdditionalDataFields: [],
  purchasedItemAdditionalDataFields: [],
});

const parseTransactionDisplayType = (type) => {
  let displayType = '';
  switch (type) {
    case 'ONLINE':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_ONLINE;
      break;
    case 'OFFLINE_POS':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS;
      break;
    case 'OFFLINE_REWARD_CLAIM':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM;
      break;
    default:
      break;
  }
  return displayType;
};

const parseEventType = (eventType) => {
  let displayEventType = '';
  switch (eventType) {
    case 'PENDING':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PENDING;
      break;
    case 'AUTHORIZED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_AUTHORIZED;
      break;
    case 'PAID':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PAID;
      break;
    case 'PARTIALLY_PAID':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_PAID;
      break;
    case 'REFUNDED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_REFUNDED;
      break;
    case 'PARTIALLY_REFUNDED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED;
      break;
    case 'VOIDED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_VOIDED;
      break;
    case 'WAITING_FOR_APPROVAL':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_WAITING;
      break;
    case 'APPROVED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_APPROVED;
      break;
    case 'RECALLED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_RECALLED;
      break;
    case 'REJECTED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_REJECTED;
      break;
    case 'EXCHANGE':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_EXCHANGE;
      break;
    default:
      break;
  }
  return displayEventType;
};

const parsePurchaseItems = (purchasedItems) => {
  const parsedPurchaseList = purchasedItems.map((item) => {
    const data = item.node;
    const extraFields = (data.extraFields && JSON.parse(data.extraFields)) || {}
    const additionalData = Object.keys(extraFields)?.map((key) => {
      return {
        title: key,
        value: typeof (extraFields[key]) === "string"
          ? extraFields[key]
          : extraFields[key] === null
            ? "" : JSON.stringify(extraFields[key])
      }
    })
    return {
      productName: data.name,
      sku: data.sku,
      category: data.category,
      subcategory: data.subcategory,
      brand: data.brand,
      quantity: data.quantity,
      originalPrice: data.originalPrice,
      displayOriginalPrice: data.originalPrice
        ? `HK$ ${formatNumberWithCommas(data.originalPrice)}`
        : '',
      value: data.value,
      displayValue: data.value
        ? `HK$ ${formatNumberWithCommas(data.value)}`
        : '',
      id: data.id,
      pk: data.pk,
      extraFields: extraFields,
      additionalData: additionalData,
      discounts: data?.discounts?.edges?.map((item) => {
        return {
          pk: item.node?.pk,
          code: item.node?.code,
          valueType: item.node.valueType,
          type: item.node.type,
          value: item.node.value,
        }
      }),
      isRefunded: data.status === "REFUNDED"
    };
  });
  return parsedPurchaseList;
};

const parseTransaction = (data) => {
  const purchasedItems = data.purchasedItems?.edges || [];
  const virtualGiftCards = data.virtualGiftCards?.edges || [];
  const giftCardCode = virtualGiftCards.map((item) => item.node.code);
  const customer = data.customer || {};
  const extraFields = (data.extraFields && JSON.parse(data.extraFields)) || {}
  const additionalData = Object.keys(extraFields)?.map((key)=>{
    return {
      title: key,
      value: typeof (extraFields[key]) === "string"
        ? extraFields[key]
        : extraFields[key] === null
          ? "" : JSON.stringify(extraFields[key])
    }
  })
  const extraStoreName = data.store?.visibleInFrontEnd ? '' : '(closed)';
  return {
    pk: data.pk,
    id: data.id,
    membershipId: customer.membershipId,
    ssoUid: customer.ssoUid,
    name: data.customer
      ? customer.nickname
        ? `${customer.firstName} ${customer.lastName} (${customer.nickname})`
        : `${customer.firstName} ${customer.lastName}`
      : null,
    transactionType: data.transactionType,
    transactionDisplayType: parseTransactionDisplayType(data.transactionType),
    storeName: data.store?.name,
    creationDate: data.creationDate,
    displayCreationDate: getDisplayDate(data.creationDate),
    transactionDate: data.date,
    displayTransactionDate: getDisplayDate(data.date),
    transactionDetailDisplayDate: formatDate(
      data.date,
      data.transactionType === 'OFFLINE_REWARD_CLAIM'
        ? TimeFormater.dayMonthYearWeek
        : TimeFormater.dayMonthYearWeekTimeA,
    ),
    purchasedDate: data.purchasedDate,
    transactionDetailDisplayPurchasedDate: formatDate(
      data.purchasedDate,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    transactionDetailCreationDate: formatDate(
      data.creationDate,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    giftCardCode: giftCardCode.join(', '),
    pointToCash: data.pointToCash,
    displayPointToCash: data.pointToCash
      ? `HK$ ${formatNumberWithCommas(data.pointToCash)}`
      : '',
    totalValue: data.totalValue,
    displayTotalValue: data.totalValue
      ? `HK$ ${formatNumberWithCommas(data.totalValue)}`
      : '',
    paidAmount: data.paidAmount,
    displayPaidAmount: data.paidAmount
      ? `HK$ ${formatNumberWithCommas(data.paidAmount)}`
      : '',
    onlineEventType: parseEventType(data.onlineEventType),
    offlineEventType: data.offlineEventType,
    displayOfflineEventType: parseEventType(data.offlineEventType),
    staffName: data.staffName,
    shippingFee: data.shippingFee,
    displayShippingFee: data.shippingFee
      ? `HK$ ${formatNumberWithCommas(data.shippingFee)}`
      : '',
    otherCharge: data.otherCharge,
    displayOtherCharge: data.otherCharge
      ? `HK$ ${formatNumberWithCommas(data.otherCharge)}`
      : '',
    remarks: data.remarks,
    posInvoiceId: data.posInvoiceId,
    invoiceId: data.invoiceId,
    receiptImage: data.receiptImage,
    creditCardSlipImage: data.creditCardSlipImage,
    purchasedItems:
      purchasedItems.length > 0 ? parsePurchaseItems(purchasedItems) : [],
    deleteDisplayName: `${getCouponExpiryDate(data.creationDate)} ${data.customer?.firstName
      } ${data.customer?.lastName} $${data.totalValue}`,
    customer: {
      pk: data.customer?.pk,
      ssoUid: data.customer?.ssoUid,
      firstName: data.customer?.firstName,
      lastName: data.customer?.lastName,
      nickname: data.customer?.nickname,
      owner: data.customer ? `${data.customer?.firstName} ${data.customer?.lastName}` : null,
      membershipId: data.customer?.membershipId,
    },
    store: {
      storeID: data.store?.id,
      storePK: data.store?.pk,
      storeName: data.store?.name,
      displayStoreName: data.store?.name ? `${data.store?.name}${extraStoreName}` : null,
    },
    createdDate: formatDate(data.creationDate),
    eventType:
      parseEventType(data.onlineEventType) ||
      parseEventType(data.offlineEventType) ||
      '-',
    displayStoreName: data.store?.name ? `${data.store?.name}${extraStoreName}` : null,
    transactionValue: data.totalValue,
    transactionPaidAmount: data.paidAmount,
    brand: data.store?.brand?.name,
    extraFields: extraFields,
    additionalData: additionalData,
    discountAmount: data?.discountAmount,
    displayDiscountAmount: data.discountAmount
      ? `HK$ ${formatNumberWithCommas(data.discountAmount)}`
      : '',
    discounts: data?.discounts?.edges?.map((item) => {
      return {
        pk: item.node?.pk,
        code: item.node?.code,
        valueType: item.node.valueType,
        type: item.node.type,
        value: item.node.value,
      }
    }),
    refundType: data.refundType,
    refundAmount: data.refund,
    refundedInvoiceId: data.refundedInvoiceId,
  };
};

export default {
  namespace: 'transactions',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    clearSelectedTransaction(state, { payload }) {
      return {
        ...state,
        selectedTransaction: {},
      };
    },

    clearData(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
    updateAllTransactionAdditionalDataFields(state, { payload }) {
      const { page, additionalDataFields } = payload;
      return {
        ...state,
        transactionAdditionalDataFields:
          page > 1
            ? [...state.transactionAdditionalDataFields, ...additionalDataFields]
            : additionalDataFields,
      };
    },
    updateAllPurchasedItemAdditionalDataFields(state, { payload }) {
      const { page, additionalDataFields } = payload;
      return {
        ...state,
        purchasedItemAdditionalDataFields:
          page > 1
            ? [...state.purchasedItemAdditionalDataFields, ...additionalDataFields]
            : additionalDataFields,
      };
    }
  },

  effects: {
    getCurrentPageTransactions: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = btoa(`arrayconnection:${(page - 1) * 20 - 1}`);
        }
        const serviceArgs = [getTransactions, afterCursor, payload];
        function* onSuccess(data) {
          const transactionData = data.transactions.edges;
          const pageInfo = data.transactions.pageInfo;
          const totalCount = data.transactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const transactionList = transactionData.map((item) => {
            return parseTransaction(item.node);
          });
          yield put(
            createAction('updateState')({
              currentPageTransactionList: transactionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    getTransaction: [
      function* ({ payload }, { call, put }) {
        const { transactionPK } = payload;
        const afterActions = payload.afterActions || (() => { });
        const transactionID = btoa(`TransactionNode:${transactionPK}`);
        const serviceArgs = [getTransaction, transactionID];
        function* onSuccess(data) {
          const transactionData = data.transaction;
          const parsedTransaction = parseTransaction(transactionData)
          yield put(
            createAction('updateState')({
              selectedTransaction: parsedTransaction,
            }),
          );
          yield put(
            createAction('createTransaction/updateState')({
              transaction: parsedTransaction,
              hasUpdatedDefaultValues: true,
            }),
          );
          afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    approveTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [approveTransaction, transactionPK];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    rejectTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [rejectTransaction, transactionPK];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    recallTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [recallTransaction, transactionPK];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    deleteTransaction: [
      function* ({ payload }, { put }) {
        const { ids } = payload;
        const serviceArgs = [deleteTransactions, ids];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getPagedTransactionAdditionDataFields: [
      function* ({ payload }, { select, put }) {
        const { actionAfterSuccess, search, page } = payload
        const afterCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getTransactionAdditionalDataFields, afterCursor, search];
        function* onSuccess(data) {
          const additionalDataFieldKeys = data?.transactionAdditionalDataFields?.edges?.map(item => item?.node?.fieldName)
          yield put({
            type: 'updateAllTransactionAdditionalDataFields',
            payload: {
              additionalDataFields: additionalDataFieldKeys,
              page: page,
            },
          });
          yield actionAfterSuccess && actionAfterSuccess(additionalDataFieldKeys)
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getPagedPurchasedItemAdditionDataFields: [
      function* ({ payload }, { select, put }) {
        const { actionAfterSuccess, search, page } = payload
        const afterCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPurchasedItemAdditionalDataFields, afterCursor, search];
        function* onSuccess(data) {
          const additionalDataFieldKeys = data?.purchasedItemAdditionalDataFields?.edges?.map(item => item?.node?.fieldName)
          yield put({
            type: 'updateAllPurchasedItemAdditionalDataFields',
            payload: {
              additionalDataFields: additionalDataFieldKeys,
              page: page,
            },
          });
          yield actionAfterSuccess && actionAfterSuccess(additionalDataFieldKeys)
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ]
  },
};
