import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import i18n from 'I18n';

import AuthButton from 'components/base/AuthButton';
import ContentSections from 'components/base/ContentSections';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import Loading from 'components/base/Loading';
import BasePrompt from 'components/base/prompt/BasePrompt';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import {
  APIStatus,
  EarningRuleType,
  ImageDomain,
  LanguageConfig,
  WEEKDAY,
} from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import { ToastType } from 'models/NavBarModel';

import { createAction } from 'utils';
import { getReadablePeriod } from 'utils/TimeFormatUtil';

function EarningRuleDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const [showPrompt, setShowPrompt] = useState(false);
  const [promptConfig, setPromptConfig] = useState({});

  const {
    earningRule,
    languages,
    createStatus,
    levelSource,
    webhookAuthenticatedKey,
    isCopied,
  } = useSelector((state) => ({
    earningRule: state.createEarningRules.earningRule,
    createStatus: state.createEarningRules.createStatus,
    languages: state.language.allList,
    levelSource: state.levels.levelList,
    webhookAuthenticatedKey: state.createEarningRules.webhookAuthenticatedKey,
    isCopied: state.createEarningRules.isCopied,
  }));

  useEffect(()=>{
    if (earningRule?.type === EarningRuleType.levelUpgrade && !levelSource) {
      dispatch(createAction('levels/getLevelList')({}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earningRule])

  const isCouponReward = earningRule.rewardType === 'COUPON' ? true : false;
  let couponRewardField = [];
  let pointRewardField = [];

  if (isCouponReward) {
    couponRewardField = couponRewardField.concat([
      [{ title: 'Coupon set', field: 'couponName' }],
    ]);
  } else {
    pointRewardField = pointRewardField.concat([
      [{ title: 'Progression Points', field: 'tpeQuantity' }],
    ]);
  }

  const limitValueFromEarningRule = {
    overallLimit: earningRule?.overallLimit,
    periodicLimit:
      earningRule?.periodicLimit && earningRule?.periodicLimitDays
        ? `${earningRule?.periodicLimit} times per ${earningRule?.periodicLimitDays} days`
        : '',
    perHeadLimit: earningRule?.perHeadLimit,
    perHeadPeriodicLimit:
      earningRule?.perHeadPeriodicLimit && earningRule?.perHeadPeriodicLimitDays
        ? `${earningRule?.perHeadPeriodicLimit} times per ${earningRule?.perHeadPeriodicLimitDays} days`
        : '',
  };

  const limitFields = [
    [{ title: 'Overall Limit (optional)', field: 'overallLimit' }],
    [{ title: 'Periodic Limit (optional)', field: 'periodicLimit' }],
    [{ title: 'Per head Limit (optional)', field: 'perHeadLimit' }],
    [
      {
        title: 'Per head periodic Limit (optional)',
        field: 'perHeadPeriodicLimit',
      },
    ],
  ];
  const levelUpgradelimitFields = [
    [
      {
        title: 'Per head periodic Limit (optional)',
        field: 'perHeadPeriodicLimit',
      },
    ],
  ];

  const renderLevelGoal = (levelGoal) => {
    if (levelSource && earningRule.type === EarningRuleType.levelUpgrade) {
      let fromLevel = levelSource.find(
        (level) => level.pk === levelGoal.pk - 1,
      );
      return fromLevel?.levelName + ' to ' + levelGoal.levelName;
    } else {
      return '-';
    }
  };

  const dataSource = {
    [EarningRuleType.generalPurchase]: {
      title: 'General Purchase',
      data: {
        ...earningRule,
        selectedStores: earningRule?.selectedStores?.map((item) => item.displayStoreName),
        specialSkus: earningRule?.specialSkus
          ?.map((item) => item.name)
          .concat(earningRule?.inputSkus?.map((item) => item)),
        displayIsExcludeDeliveryCost: earningRule?.isExcludeDeliveryCost
          ? 'Yes'
          : 'No',
        displayIsExcludeOtherCharges: earningRule?.isExcludeOtherCharges
          ? 'Yes'
          : 'No',
        displayBlackoutPeriod: earningRule?.activePeriodValues?.map((item) =>
          getReadablePeriod(item.startDate, item.endDate, false),
        ),
        displayBlackoutWeekend: earningRule?.activeWeekday?.map(
          (item) => WEEKDAY[item],
        ),
        ...limitValueFromEarningRule,
      },
      fields: [
        [{ title: 'For stamp campaign', field: 'displayLinkedStampCampaign' }],
        [{ title: 'Minimum spending', field: 'minSpending' }],
        [{ title: 'Maximum spending (optional)', field: 'maxSpending' }],
        [
          {
            title: 'Eligible number of days since purchase (optional)',
            field: 'eligibleDays',
          },
        ],
        [{ title: 'Limited to store (optional)', field: 'selectedStores' }],
        [{ title: 'Special SKU (optional)', field: 'specialSkus' }],
        [
          {
            title: 'Will exclude delivery cost',
            field: 'displayIsExcludeDeliveryCost',
          },
        ],
        [
          {
            title: 'Will exclude other charges',
            field: 'displayIsExcludeOtherCharges',
          },
        ],
        [{ title: 'In birth-month', field: 'displayIsInBirthMonth' }],
        [{ title: 'Exclusive', field: 'displayIsExclusive' }],
        [
          {
            title: 'Target customer groups',
            field: 'displayTargetCustomerGroups',
          },
        ],
        [{ title: 'Target segments', field: 'displayTargetedSegments' }],
        [{ title: 'Target levels', field: 'displayTargetedLevels' }],
        [{ title: 'Active period', field: 'displayActivePeriod' }],
        [
          {
            title: 'Blackout period (by period)',
            field: 'displayBlackoutPeriod',
          },
        ],
        [
          {
            title: 'Blackout period (by weekday)',
            field: 'displayBlackoutWeekend',
          },
        ],
        ...limitFields,
      ],
    },
    [EarningRuleType.gpsCheckIn]: {
      title: 'GPS Check-in',
      data: { ...earningRule, ...limitValueFromEarningRule },
      fields: [
        [{ title: 'For stamp campaign', field: 'displayLinkedStampCampaign' }],
        [{ title: 'Latitude', field: 'latitude' }],
        [{ title: 'Longitude', field: 'longitude' }],
        [{ title: 'Radius', field: 'radius' }],
        ...limitFields,
      ],
    },
    [EarningRuleType.memberReferral]: {
      title: 'Referral',
      data: earningRule,
      fields: [
        [{ title: 'For stamp campaign', field: 'displayLinkedStampCampaign' }],
        [{ title: 'Beneficiary', field: 'referralType' }],
        [
          {
            title: 'Maximum number of invitees (optional, for inviters only)',
            field: 'maxInvitees',
          },
        ],
      ],
    },
    [EarningRuleType.birthday]: {
      title: 'Birthday',
      data: {
        ...earningRule,
        displayBlackoutPeriod: earningRule?.activePeriodValues?.map((item) =>
          getReadablePeriod(item.startDate, item.endDate, false),
        ),
        displayBlackoutWeekend: earningRule?.activeWeekday?.map(
          (item) => WEEKDAY[item],
        ),
        // birthdayPeriod: `${earningRule?.birthStart} - ${earningRule?.birthEnd}`,
      },
      fields: [
        [{ title: 'For stamp campaign', field: 'displayLinkedStampCampaign' }],
        // [{ title: 'Birthday Period', field: 'birthdayPeriod' }],
        [{ title: 'Exclusive', field: 'displayIsExclusive' }],
        [
          {
            title: 'Target customer groups',
            field: 'displayTargetCustomerGroups',
          },
        ],
        [{ title: 'Target segments', field: 'displayTargetedSegments' }],
        [{ title: 'Target levels', field: 'displayTargetedLevels' }],
        [{ title: 'Active period', field: 'displayActivePeriod' }],
        [
          {
            title: 'Blackout period (by period)',
            field: 'displayBlackoutPeriod',
          },
        ],
        [
          {
            title: 'Blackout period (by weekday)',
            field: 'displayBlackoutWeekend',
          },
        ],
      ],
    },
    [EarningRuleType.qrCodeScanning]: {
      title: 'QR Code Scanning',
      data: { ...earningRule, ...limitValueFromEarningRule },
      fields: [
        [{ title: 'For stamp campaign', field: 'displayLinkedStampCampaign' }],
        [{ title: 'QR codes', field: 'qrCodes' }],
        ...limitFields,
      ],
    },
    [EarningRuleType.newMember]: {
      title: 'New Member',
      data: {
        ...earningRule,
        displayBlackoutPeriod: earningRule?.activePeriodValues?.map((item) =>
          getReadablePeriod(item.startDate, item.endDate, false),
        ),
        displayBlackoutWeekend: earningRule?.activeWeekday?.map(
          (item) => WEEKDAY[item],
        ),
      },
      fields: [
        [{ title: 'For stamp campaign', field: 'displayLinkedStampCampaign' }],
        [{ title: 'Exclusive', field: 'displayIsExclusive' }],
        [
          {
            title: 'Target customer groups',
            field: 'displayTargetCustomerGroups',
          },
        ],
        [{ title: 'Target segments', field: 'displayTargetedSegments' }],
        [{ title: 'Target levels', field: 'displayTargetedLevels' }],
        [{ title: 'Active period', field: 'displayActivePeriod' }],
        [
          {
            title: 'Blackout period (by period)',
            field: 'displayBlackoutPeriod',
          },
        ],
        [
          {
            title: 'Blackout period (by weekday)',
            field: 'displayBlackoutWeekend',
          },
        ],
      ],
    },
    [EarningRuleType.fillingForm]: {
      title: 'Webhook',
      data: {
        ...earningRule,
        ...limitValueFromEarningRule,
        displayBlackoutPeriod: earningRule?.activePeriodValues?.map((item) =>
          getReadablePeriod(item.startDate, item.endDate, false),
        ),
        displayBlackoutWeekend: earningRule?.activeWeekday?.map(
          (item) => WEEKDAY[item],
        ),
      },
      fields: [
        [{ title: 'For stamp campaign', field: 'displayLinkedStampCampaign' }],
        [{ title: 'Exclusive', field: 'displayIsExclusive' }],
        [
          {
            title: 'Target customer groups',
            field: 'displayTargetCustomerGroups',
          },
        ],
        [{ title: 'Target segments', field: 'displayTargetedSegments' }],
        [{ title: 'Target levels', field: 'displayTargetedLevels' }],
        [{ title: 'Active period', field: 'displayActivePeriod' }],
        [
          {
            title: 'Blackout period (by period)',
            field: 'displayBlackoutPeriod',
          },
        ],
        [
          {
            title: 'Blackout period (by weekday)',
            field: 'displayBlackoutWeekend',
          },
        ],
        ...limitFields,
      ],
    },
    [EarningRuleType.levelUpgrade]: {
      title: 'Level Upgrade',
      data: {
        ...earningRule,
        ...limitValueFromEarningRule,
        levelGoal: renderLevelGoal(earningRule?.levelGoal),
        displayBlackoutPeriod: earningRule?.activePeriodValues?.map((item) =>
          getReadablePeriod(item.startDate, item.endDate, false),
        ),
        displayBlackoutWeekend: earningRule?.activeWeekday?.map(
          (item) => WEEKDAY[item],
        ),
      },
      fields: [
        [{ title: 'For stamp campaign', field: 'displayLinkedStampCampaign' }],
        [{ title: 'Level upgrade (from, to)', field: 'levelGoal' }],
        [{ title: 'Exclusive', field: 'displayIsExclusive' }],
        [
          {
            title: 'Target customer groups',
            field: 'displayTargetCustomerGroups',
          },
        ],
        [{ title: 'Target segments', field: 'displayTargetedSegments' }],
        [{ title: 'Target levels', field: 'displayTargetedLevels' }],
        [{ title: 'Active period', field: 'displayActivePeriod' }],
        [
          {
            title: 'Blackout period (by period)',
            field: 'displayBlackoutPeriod',
          },
        ],
        [
          {
            title: 'Blackout period (by weekday)',
            field: 'displayBlackoutWeekend',
          },
        ],
        ...levelUpgradelimitFields,
      ],
    },
    REWARD: {
      title: 'Reward',
      data: {
        ...earningRule,
        couponName: `[ID: ${earningRule?.coupons?.pk}] ${earningRule?.coupons?.name}`,
        rewardType: isCouponReward ? 'Coupons' : 'Points',
      },
      fields: [
        [{ title: 'Reward type', field: 'rewardType' }],
        ...couponRewardField,
        [{ title: 'Quantity', field: 'quantity' }],
        ...pointRewardField,
      ],
    },
  };

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(createAction('createEarningRules/getOneEarningRule')({ id }));

    return () => dispatch({ type: 'createEarningRules/clearData' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      id &&
      earningRule?.type === EarningRuleType.fillingForm
    ) {
      dispatch(
        createAction('createEarningRules/getWebhookAuthenticatedKey')({
          earningRuleId: id,
        }),
      );
    }
  }, [earningRule]);

  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    if (valueKeys?.length > 0) {
      valueKeys.forEach((valueKey) => {
        data[valueKey] = earningRule?.translations?.[language]?.[valueKey];
      });
    }
    return data;
  };

  const renderTabForLanguage = (language) => {
    const data = getValueFromTranslations(language, [
      'name',
      'instructionSectionTitle',
      'instructionSectionContent',
      'detailSectionTitle',
      'detailSectionContent',
    ]);
    if (!data?.name) {
      data.name = earningRule.generalName;
    }

    const extraFields =
      earningRule?.linkedStampCampaign ||
      earningRule?.type === EarningRuleType.newMember ||
      earningRule?.type === EarningRuleType.birthday ||
      earningRule?.type === EarningRuleType.generalPurchase ||
      earningRule?.type === EarningRuleType.fillingForm ||
      earningRule?.type === EarningRuleType.levelUpgrade
        ? []
        : [
            [
              {
                title: i18n.t('instruction_section_title', {
                  locale: language,
                }),
                field: 'instructionSectionTitle',
              },
            ],
            [
              {
                title: i18n.t('instruction_section_content', {
                  locale: language,
                }),
                field: 'instructionSectionContent',
              },
            ],
            [
              {
                title: i18n.t('detail_section_title', { locale: language }),
                field: 'detailSectionTitle',
              },
            ],
            [
              {
                title: i18n.t('detail_section_content', { locale: language }),
                field: 'detailSectionContent',
              },
            ],
          ];

    return (
      <MembershipInfoCard
        title={i18n.t('content', { locale: language })}
        data={data}
        fields={[
          [{ title: i18n.t('name', { locale: language }), field: 'name' }],
          ...extraFields,
        ]}
      />
    );
  };

  const section = ({ title, data, fields }) => {
    if (title && data && fields) {
      return [<MembershipInfoCard title={title} data={data} fields={fields} />];
    }
    return [];
  };

  const tabs = [
    {
      name: 'Detail',
      content:
        createStatus === APIStatus.calling ? (
          <Loading />
        ) : (
          <>
            <ContentSections
              hidePreview
              activeSection={LanguageConfig.english}
              contentStyle="campaign-detail-language-content-sections"
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: [renderTabForLanguage(item.code)],
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
            />
            <ContentSections
              sections={section(dataSource?.[earningRule?.type] || {})}
              hidePreview
              contentStyle="campaign-detail-language-content-sections"
            />
            <ContentSections
              sections={section(dataSource.REWARD)}
              hidePreview
            />
          </>
        ),
    },
  ];

  const copiedToastAction = () => {
    dispatch({
      type: 'navBars/updateState',
      payload: {
        saveDiscardToastShowing: {
          value: true,
          type: ToastType.copied,
        },
      },
    });
  };

  const copyWebhookURLAction = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_DOMAIN}/webhooks/earning_rules/${id}`,
    );
    copiedToastAction();
  };

  const copyWebhookAuthenticatedKeyAction = (authenticatedKey) => {
    navigator.clipboard.writeText(authenticatedKey?.key);
    dispatch({
      type: 'createEarningRules/createOrUpdateWebhookAuthenticatedKey',
      payload: { id: authenticatedKey?.pk, isCopied: true },
    });
    copiedToastAction();
  };

  const copyAuthenticationKeyAction = () => {
    setShowPrompt(true);
    setPromptConfig({
      title: 'Keep the authenticated key save',
      description: `Upon click "Confirm" below, the authenticated key will be copied to the clipboard. Please make sure you save it, you won't be able to access it again.`,
      leftButton: {
        text: 'Cancel',
        action: () => {
          setShowPrompt(false);
          setPromptConfig({});
        },
      },
      rightButton: {
        text: 'Confirm',
        action: () => {
          copyWebhookAuthenticatedKeyAction(webhookAuthenticatedKey);
          setShowPrompt(false);
          setPromptConfig({});
        },
      },
    });
  };

  const generateAuthenticationKeyAction = () => {
    setShowPrompt(true);
    setPromptConfig({
      title: 'Heads up',
      description: `Upon click "Confirm" below, a new authenticated key will be copied to your clipboard but the the existing authentication key would become invalid.`,
      leftButton: {
        text: 'Cancel',
        action: () => {
          setShowPrompt(false);
          setPromptConfig({});
        },
      },
      rightButton: {
        text: 'Confirm',
        action: () => {
          dispatch({
            type: 'createEarningRules/createOrUpdateWebhookAuthenticatedKey',
            payload: {
              earningRuleId: id,
              afterAction: (authenticatedKey) => {
                copyWebhookAuthenticatedKeyAction(authenticatedKey);
              },
            },
          });
          setShowPrompt(false);
          setPromptConfig({});
        },
      },
    });
  };

  const buttons = [
    <ListButtonsGroup
      primaryButton={
        <AuthButton
          title="Edit"
          action={() => {
            history.push({
              pathname: 'edit/',
            });
          }}
          requires={PermissionCodes.changeEarningRule}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.changeEarningRule,
        action: () => {
          history.push({
            pathname: 'edit/',
          });
        },
        content: 'Edit',
      }}
      extraButtons={
        earningRule?.type === EarningRuleType.fillingForm
          ? [
              <AuthButton
                title="Guide"
                customClass="btn-back-button-common btn-download"
                href={`${ImageDomain}Webhook-Guide.pdf`}
                target={'_blank'}
                requires={PermissionCodes.changeEarningRule}
              />,
              <AuthButton
                title="Copy Webhook URL"
                customClass="btn-back-button-common btn-download"
                action={copyWebhookURLAction}
                requires={PermissionCodes.changeEarningRule}
              />,
              <AuthButton
                title={'Copy Authenticated Key'}
                customClass="btn-back-button-common btn-download"
                action={
                  isCopied
                    ? generateAuthenticationKeyAction
                    : copyAuthenticationKeyAction
                }
                requires={PermissionCodes.changeEarningRule}
              />,
            ]
          : []
      }
      extraPopContent={
        earningRule?.type === EarningRuleType.fillingForm
          ? [
              {
                requires: PermissionCodes.changeEarningRule,
                action: () => {},
                content: 'Guide',
              },
              {
                requires: PermissionCodes.changeEarningRule,
                action: copyWebhookURLAction,
                content: 'Copy Webhook URL',
              },
              {
                requires: PermissionCodes.changeEarningRule,
                action: isCopied
                  ? generateAuthenticationKeyAction
                  : copyAuthenticationKeyAction,
                content: 'Copy Authenticated Key',
              },
            ]
          : []
      }
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: earningRule.generalName,
        }}
        hideTab
        breadcrumb={<CustomBreadcrumb name={earningRule.generalName} />}
        buttons={buttons}
        tabs={tabs}
      />
      <BasePrompt
        show={showPrompt}
        closeAction={() => {
          setShowPrompt(false);
          setPromptConfig({});
        }}
        leftButton={{ ...promptConfig?.leftButton }}
        rightButton={{ ...promptConfig?.rightButton }}
        title={promptConfig?.title}
        description={promptConfig?.description}
      />
    </>
  );
}

export default EarningRuleDetail;
