import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import { hasErrorFromList } from 'components/base/ErrorFieldMessage';
import CustomRadioWithTitleAndRelated from '../customer/CustomRadioWithTitleAndRelated';
import CustomTitleWithSwitch from 'components/base/CustomTitleWithSwitch';
import { EditCustomerHandleError } from 'components/customer/EditCustomerHandleError';
import { SIGN_UP_METHOD } from 'config/CustomEnums';


const EditPersonalInformationModal = ({
  customer,
  errors,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  const [isForcedInactive, setIsForcedInactive] = useState(customer.status === "Deactive");
  const [email, setEmail] = useState(customer.email);
  const [firstName, setFirstName] = useState(customer.firstName);
  const [lastName, setLastName] = useState(customer.lastName);
  const [nickName, setNickName] = useState(customer.nickname);
  const [gender, setGender] = useState(customer.gender);
  const [address, setAddress] = useState(customer.address);
  const [isTestUser, setIsTestUser] = useState(customer.isAssignedAsTestingCustomer);


  const onClose = () => {
    setShow(false);
  }


  const saveAction = () => {
    dispatch({
      type: 'customerList/checkCustomerProfileValid',
      payload: {
        data: {
          ...customer,
          isForcedInactive,
          emailAddress: email,
          firstName,
          lastName,
          nickname: nickName,
          gender,
          address,
          isAssignedAsTestingCustomer: isTestUser,
        },
        customer: customer,
        refreshCustomer: true,
      },
    });
  };

  useEffect(() => {
    if (show) {
      dispatch({
        type: 'customerList/updateState',
        payload: {
          errorsList: [],
        }
      })

      setIsForcedInactive(customer.status === "Deactive");
      setEmail(customer.email);
      setFirstName(customer.firstName);
      setLastName(customer.lastName);
      setNickName(customer.nickname);
      setGender(customer.gender);
      setAddress(customer.address);
      setIsTestUser(customer.isAssignedAsTestingCustomer);
    }
  }, [show])

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container customer-detail-prompt`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={onClose}
        />

        <label className='customer-detail-modal-title'>Edit personal information</label>

        <CustomTitleWithSwitch
          title={'Is forced inactive'}
          customTitleClass={'customer-detail-prompt-field-title'}
          defaultValue={isForcedInactive}
          setValue={(value) => {
            setIsForcedInactive(value);
          }}
        />
{/* 
        {
          customer.signUpMethod === SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
            ? <>
              <CustomTitleWithInput
                title={'Email (For contact)'}
                customTitleClass='customer-detail-prompt-field-title'
                defaultValue={email}
                setValue={(value) => {
                  setEmail(value);
                }}
              />
            </>
            : null
        } */}

        <CustomTitleWithInput
          title={'Email (For contact)'}
          customTitleClass='customer-detail-prompt-field-title'
          defaultValue={email}
          setValue={(value) => {
            setEmail(value);
          }}
        />

        <CustomTitleWithInput
          title={'First name'}
          customTitleClass='customer-detail-prompt-field-title'
          defaultValue={firstName}
          setValue={(value) => {
            setFirstName(value);
          }}
          error={{
            id: EditCustomerHandleError.firstName.name,
            error: hasErrorFromList(errors, EditCustomerHandleError.firstName.name),
            message: EditCustomerHandleError.firstName.message,
          }}
        />

        <CustomTitleWithInput
          title={'Last name'}
          customTitleClass='customer-detail-prompt-field-title'
          defaultValue={lastName}
          setValue={(value) => {
            setLastName(value);
          }}
          error={{
            id: EditCustomerHandleError.lastName.name,
            error: hasErrorFromList(errors, EditCustomerHandleError.lastName.name),
            message: EditCustomerHandleError.lastName.message,
          }}
        />

        <CustomTitleWithInput
          title={'Preferred name (optional)'}
          customTitleClass='customer-detail-prompt-field-title'
          defaultValue={nickName}
          setValue={(value) => {
            setNickName(value);
          }}
        />

        <CustomRadioWithTitleAndRelated
          title="Gender (optional)"
          customTitleClass='customer-detail-prompt-field-title'
          options={[
            { label: 'Male', value: 'MALE' },
            { label: 'Female', value: 'FEMALE' },
            { label: 'Not disclosed', value: 'NOT_DISCLOSED' },
          ]}
          defaultValue={gender}
          setValue={(value) => {
            setGender(value);
          }}
        />

        <CustomTitleWithInput
          title={'Address (optional)'}
          customTitleClass='customer-detail-prompt-field-title'
          defaultValue={address}
          useDefaultValue={false}
          setValue={(value) => {
            setAddress(value);
          }}
        />

        <CustomTitleWithSwitch
          title={'Assign as test customer'}
          customTitleClass='customer-detail-prompt-field-title'
          defaultValue={isTestUser}
          setValue={(value) => {
            setIsTestUser(value);
          }}
        />

        <div className="base-prompt-buttons customer-detail-prompt-buttons">
          <PromptButton
            title='Cancel'
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title='Confirm'
            action={saveAction}
            requires={PermissionCodes.changeCustomer}
            type={BUTTON_TYPES.main}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
  errors: state.customerList.errorsList,
});

export default connect(mapPropsToState)(EditPersonalInformationModal);
