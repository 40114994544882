import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { DISCOUNT_TYPE, DISCOUNT_VALUE_TYPE } from 'config/CustomEnums';

import AuthButton from '../../../components/base/AuthButton';
import {
  OnlyBackButton,
  SaveAndBackButtons,
  SaveAndBackWithOutTempButtons,
} from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { transactionQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';
import RedirectToShopifyPrompt from '../../../components/transactions/RedirectToShopifyPrompt';
import TransactionActionPrompt from '../../../components/transactions/TransactionActionPrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import {
  TRANSACTION_EVENT_TYPE,
  TRANSACTION_RECORD_TYPE,
} from './TransactionList';

function TransactionOverView({ selectedTransaction }) {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const transactionPK = useParams().id;

  const [showActionPrompt, setShowActionPrompt] = useState(false);
  const [promptActionTitle, setPromptActionTitle] = useState();
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  useEffect(() => {
    dispatch(createAction('transactions/getTransaction')({ transactionPK }));
  }, [dispatch, transactionPK]);

  const getFieldsWithTransactionType = () => {
    const transactionType = selectedTransaction.transactionDisplayType;
    const showRefundedInvoiceId =
      selectedTransaction.transactionDisplayType ===
        TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS &&
      [
        TRANSACTION_EVENT_TYPE.TYPE_REFUNDED,
        TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED,
        TRANSACTION_EVENT_TYPE.TYPE_VOIDED,
      ].includes(selectedTransaction.displayOfflineEventType);
    console.log(transactionType, selectedTransaction, showRefundedInvoiceId);
    let eventType = [];
    let extraData = [];
    switch (transactionType) {
      case TRANSACTION_RECORD_TYPE.TYPE_ONLINE:
        eventType = [{ title: 'Online event type', field: 'onlineEventType' }];
        extraData = [
          [{ title: 'Cash dollar', field: 'displayPointToCash' }],
          [{ title: 'Shipping fee', field: 'displayShippingFee' }],
          [{ title: 'Other charges', field: 'displayOtherCharge' }],
          [{ title: 'Total discount', field: 'displayDiscountAmount' }],
          [{ title: 'Eligible Total', field: 'displayTotalValue' }],
          [{ title: 'Paid amount', field: 'displayPaidAmount' }],
          ...selectedTransaction?.discounts?.map(
            (discount, index) => {
              return [
                {
                  title: `discounts #${index + 1} (optional)`,
                  field: 'discounts',
                  customRender: discountDisplay(discount, true),
                },
              ];
            },
          ),
        ];
        break;
      case TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM:
        eventType = [
          { title: 'Offline event type', field: 'displayOfflineEventType' },
        ];
        extraData = [
          [{ title: 'Cash dollar', field: 'displayPointToCash' }],
          [{ title: 'Receipt image', field: 'receiptImage' }],
          [{ title: 'Credit card slip image', field: 'creditCardSlipImage' }],
          [{ title: 'Eligible Total', field: 'displayTotalValue' }],
          [{ title: 'Paid amount', field: 'displayPaidAmount' }],
        ];
        break;
      case TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS:
        eventType = [
          { title: 'Offline event type', field: 'displayOfflineEventType' },
        ];
        const discountsFields = selectedTransaction?.discounts?.map(
          (discount, index) => {
            return [
              {
                title: `discounts #${index + 1} (optional)`,
                field: 'discounts',
                customRender: discountDisplay(discount),
              },
            ];
          },
        );
        extraData = [
          [{ title: 'Cash dollar', field: 'displayPointToCash' }],
          [{ title: 'Shipping fee', field: 'displayShippingFee' }],
          [{ title: 'Other charges', field: 'displayOtherCharge' }],
          [{ title: 'Total discount', field: 'displayDiscountAmount' }],
          [{ title: 'Eligible Total', field: 'displayTotalValue' }],
          [{ title: 'Paid amount', field: 'displayPaidAmount' }],
          ...discountsFields,
        ];
        break;
      default:
        break;
    }
    const data = [
      [{ title: 'Customer', field: 'name' }],
      [{ title: 'Creation date', field: 'transactionDetailCreationDate' }],
      [{ title: 'Transaction date', field: 'transactionDetailDisplayDate' }],
      transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS 
      ? [{ title: 'Purchased date', field: 'transactionDetailDisplayPurchasedDate' }]
      : [],
      [{ title: 'Store', field: 'displayStoreName' }],
      [{ title: 'Gift card code', field: 'giftCardCode' }],
      [
        {
          title: 'Invoice id',
          field:
            selectedTransaction.transactionDisplayType ===
            TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
              ? 'posInvoiceId'
              : 'invoiceId',
        },
      ],
      showRefundedInvoiceId
        ? [{ title: 'Refunded invoice id', field: 'refundedInvoiceId' }]
        : [],
      eventType,
      [{ title: 'Staff name', field: 'staffName' }],
      [{ title: 'Remarks (optional)', field: 'remarks', isRichText: true }],
      ...extraData,
    ];
    return data;
  };
  const additionalDataDisplay = (data) => {
    return (
      <div>
        <div>{`Title: ${data.title || '-'}`}</div>
        <div>{`Value: ${data.value || '-'}`}</div>
      </div>
    );
  };

  const getFieldAdditionData = () => {
    const additionalData = selectedTransaction.additionalData;
    const filedList = additionalData?.map((item, index) => {
      return [
        {
          title: `Fields #${index + 1} (optional)`,
          field: item.title,
          customRender: additionalDataDisplay(item),
        },
      ];
    });

    return filedList || [];
  };
  const offlineStoreDiscountTypeDisplayMap = {
    [DISCOUNT_TYPE.TRANSACTION]: 'Transaction-level',
    [DISCOUNT_TYPE.ITEM]: 'Purchase item-level',
  }
  const onlineStoreDiscountTypeMap = {
    [DISCOUNT_TYPE.TRANSACTION]: 'From online transaction - Order discount',
    [DISCOUNT_TYPE.ITEM]: 'From online transaction - Product discount',
    [DISCOUNT_TYPE.SHIPPING]: 'From online transaction - Shipping discount',
    [DISCOUNT_TYPE.AILLIA_LEVEL_DISCOUNT]: 'From online transaction - Level discount',
    [DISCOUNT_TYPE.AILLIA_FREE_SHIPPING_DISCOUNT]: 'From online transaction - Free shipping discount',
    [DISCOUNT_TYPE.AILLIA_POINT_TO_CASH]: 'From online transaction - Point to cash discount',
  }
  const discountDisplay = (discount, isOnlineStoreDiscount = false) => {
    let displayValue = '-';
    if (discount.valueType === DISCOUNT_VALUE_TYPE.FIXED_VALUE) {
      displayValue = `Fixed amount - ${discount.value || ''}`;
    }
    if (discount.valueType === DISCOUNT_VALUE_TYPE.PERCENTAGE) {
      displayValue = `Percentage - x${discount.value || ''}%`;
    }
    const displayDiscountType = isOnlineStoreDiscount
      ? onlineStoreDiscountTypeMap?.[discount.type]|| "-"
      : offlineStoreDiscountTypeDisplayMap?.[discount.type] || '-'
    
    return (
      <div>
        <div>{`Type: ${displayDiscountType}`}</div>
        <div>{`Value: ${displayValue}`}</div>
        <div>{`Code: ${discount.code || '-'}`}</div>
      </div>
    );
  };

  const getContentSections = () => {
    const additionalData = selectedTransaction.extraFields;

    let sections = [
      <MembershipInfoCard
        title="TRANSACTION DETAILS"
        data={selectedTransaction}
        fields={getFieldsWithTransactionType(
          selectedTransaction.transactionDisplayType,
        )}
      />,
      Object.keys(additionalData || {}).length > 0 ? (
        <MembershipInfoCard
          title="ADDITIONAL DATA"
          data={additionalData}
          fields={getFieldAdditionData()}
        />
      ) : null,
    ];

    let purchaseItemSectionList = [];
    if (
      selectedTransaction.transactionDisplayType !==
      TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
    ) {
      purchaseItemSectionList = selectedTransaction.purchasedItems?.map(
        (item, index) => {
          const extraDataFields = item?.additionalData?.map((item, index) => {
            return [
              {
                title: `Fields #${index + 1} (optional)`,
                field: item?.title,
                customRender: additionalDataDisplay(item),
              },
            ];
          });
          const discountFields = item.discounts?.map((discount, index) => {
            return [
              {
                title: `discounts #${index + 1} (optional)`,
                field: 'discounts',
                customRender: discountDisplay(discount, selectedTransaction.transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_ONLINE),
              },
            ];
          });

          const fields = [
            [{ title: 'Product name', field: 'productName' }],
            [{ title: 'Sku (optional)', field: 'sku' }],
            [{ title: 'Category (optional)', field: 'category' }],
            [{ title: 'Subcategory (optional)', field: 'subcategory' }],
            [{ title: 'Brand (optional)', field: 'brand' }],
            [{ title: 'Quantity', field: 'quantity' }],
            [{ title: 'Original Price', field: 'displayOriginalPrice' }],
            [{ title: 'Final Price', field: 'displayValue' }],
            ...discountFields,
            ...extraDataFields,
          ];
          return (
            <MembershipInfoCard
              title={`PURCHASED ITEM #${index + 1}`}
              data={item}
              fields={fields}
            />
          );
        },
      );
    }
    sections = sections.concat(purchaseItemSectionList);
    return sections;
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={getContentSections()}
            hidePreview={true}
            // sectionStyle="transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  const buttons = () => {
    if (
      selectedTransaction.transactionDisplayType ===
      TRANSACTION_RECORD_TYPE.TYPE_ONLINE
    ) {
      return [
        <AuthButton
          title="Edit"
          action={() => {
            setShowRedirectPrompt(true);
          }}
          requires={PermissionCodes.changeTransaction}
        />,
      ];
    }
    if (
      selectedTransaction.transactionDisplayType ===
      TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
    ) {
      return [
        <AuthButton
          title="Edit"
          action={() => {
            history.push(`/transactions/${selectedTransaction.pk}/edit`);
          }}
          requires={PermissionCodes.changeTransaction}
        />,
      ];
    }
  };

  const goBackToList = () => {
    return history.push({
      pathname: '/transactions',
      search: queryString.stringify({
        [transactionQueryKey.searchKey]: '',
        [transactionQueryKey.page]: 1,
        [transactionQueryKey.rank]: true,
        [transactionQueryKey.tab]: 'all',
      }),
    });
  };

  const backAction = () => {
    dispatch(createAction('transactions/clearSelectedTransaction')());
    goBackToList();
  };

  const getBottomSection = () => {
    if (
      selectedTransaction.transactionDisplayType ===
      TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
    ) {
      if (
        selectedTransaction.displayOfflineEventType ===
        TRANSACTION_EVENT_TYPE.TYPE_WAITING
      ) {
        return (
          <SaveAndBackButtons
            backAction={backAction}
            saveTempText={'Reject'}
            saveTempAction={() => {
              setPromptActionTitle('reject');
              setShowActionPrompt(true);
            }}
            saveText={'Approve'}
            saveAction={() => {
              setPromptActionTitle('approve');
              setShowActionPrompt(true);
            }}
            tempRequires={PermissionCodes.changeTransaction}
            saveRequires={PermissionCodes.changeTransaction}
          />
        );
      }
      if (
        selectedTransaction.displayOfflineEventType ===
        TRANSACTION_EVENT_TYPE.TYPE_APPROVED
      ) {
        return (
          <SaveAndBackWithOutTempButtons
            backAction={backAction}
            saveText={'Recall'}
            actionText={'Recall'}
            saveAction={() => {
              setPromptActionTitle('recall');
              setShowActionPrompt(true);
            }}
            saveRequires={PermissionCodes.changeTransaction}
          />
        );
      }
    }

    return (
      <OnlyBackButton
        backAction={() => {
          goBackToList();
        }}
      />
    );
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view transaction
          title: 'View transaction',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={buttons()}
      />
      {getBottomSection()}
      <TransactionActionPrompt
        event={promptActionTitle}
        item={selectedTransaction}
        show={showActionPrompt}
        onHide={() => setShowActionPrompt(false)}
        action={() => {
          dispatch(
            createAction(`transactions/${promptActionTitle}Transaction`)({
              transactionPK: selectedTransaction.pk,
              afterActions: backAction(),
            }),
          );
        }}
      />
      <RedirectToShopifyPrompt
        show={showRedirectPrompt}
        onHide={() => setShowRedirectPrompt(false)}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  selectedTransaction: state.transactions.selectedTransaction,
});

export default connect(mapPropsToState)(TransactionOverView);
