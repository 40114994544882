import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import {
  SectionTopTitle,
} from '../base/CommonComponent';
import { AdminErrorHandleFields } from '../../containers/admin/admins/AdminErrorHandleFields';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { Button, Row } from 'react-bootstrap';
import ChangePasswordPrompt from './ChangePasswordPrompt';
import { ToastType } from '../../models/NavBarModel';
import InputFieldControl from '../base/InputFieldControl';
import { FieldControl } from '../base/CommonComponent';
import CustomRadios from '../base/CustomRadios';
import { ADMINISTRATOR_TYPES } from '../../config/CustomEnums';
import { createAction } from '../../utils';
import StoreSelectDropdown from '../transactions/StoreSelectDropdown';
import BrandSelectDropdown from '../campaign/campaignCreation/BrandSelectDropdown';
import { PermissionCodes } from '../../config/PermissionCodes';
import BasePrompt from '../base/prompt/BasePrompt';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';

export const ADMINISTRATOR_TYPES_CONFIG = [
  {
    label: 'CMS Administrator',
    value: ADMINISTRATOR_TYPES.CMS_ADMINISTRATOR
  },
  {
    label: 'Merchant app account',
    value: ADMINISTRATOR_TYPES.MERCHANT_APP_ACCOUNT
  },
]

const ChangePasswordCom = ({ changeAction }) => {
  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title="Password" />
      <label className="tips-message">
        Raw passwords are not stored, so there is no way to see this user's
        password.
      </label>
      <label className="tips-message">{'*'.repeat(10)}</label>
      <Button className="btn-back-button-common" onClick={changeAction}>
        Change
      </Button>
    </div>
  );
};

function GeneralSections({
  adminName
}) {
  const params = useParams();
  const { id } = params;
  const [showChangePassPrompt, setShowChangePassPrompt] = useState(false);
  const { watch, setValue, formState: { errors } } = useFormContext();
  const watchName = watch('name');
  const watchPassword = watch('password');
  const watchConfirmPassword = watch('confirmPassword');
  const watchAdminType = watch('adminType');
  const watchBrand = watch('brand');
  const watchStore = watch('store');
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCreateBrandPrompt, setShowCreateBrandPrompt] = useState(false);

  useEffect(() => {
    if (watchBrand?.pk > 0) {
      dispatch(
        createAction('storeModel/getPagedStoreList')({
          brand: watchBrand.pk,
          isSimpleNode: true,
          isOnlineStore: false,
          isSelectorLoad: true,
          search: '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBrand])

  const passwordRex = /\D/g;
  const nameSection = (
    <InputFieldControl
      name={'name'}
      title={'Name'}
      rules={{ required: AdminErrorHandleFields.required.username }}
      value={watchName}
      setValue={(value) => {
        setValue('name', value, { shouldDirty: true });
      }}
    />
  );
  const adminTypeSection = (
    <div className="d-flex flex-column">
      <CustomTitleLabel title="Administrator type" />
      <FieldControl
        name="adminType"
        rules={{ required: AdminErrorHandleFields.required.adminType }}
        render={() => (<>
          <Row className="featured-exclusive-row">
            <CustomRadios
              onChange={(value) => {
                setValue('adminType', value, { shouldDirty: true });
              }}
              options={ADMINISTRATOR_TYPES_CONFIG}
            />
          </Row>
          <ReactHookFormErrorMessage errors={errors} id={'adminType'} />
        </>
        )}
      />

    </div>
  )
  const passwordSection = (
    <InputFieldControl
      name={'password'}
      title={'Password'}
      autoComplete="new-password"
      tips="Password at least 8 characters, can't be entirely numeric."
      type="password"
      rules={{
        validate: {
          lessThanEight: (value) => {
            return (
              value?.length >= 8 ||
              AdminErrorHandleFields.others.password.length
            );
          },
          allNumeric: (value) => {
            return (
              passwordRex.test(value) ||
              AdminErrorHandleFields.others.password.numeric
            );
          },
        },
      }}
      value={watchPassword}
      setValue={(value) => {
        setValue('password', value, { shouldDirty: true });
      }}
    />
  );
  const confirmPasswordSection = (
    <InputFieldControl
      name={'confirmPassword'}
      title={'Password confirmation'}
      tips="Enter the same password as before, for verification"
      type="password"
      autoComplete="new-password"
      rules={{
        validate: {
          lessThanEight: (value) => {
            return (
              value?.length ||
              AdminErrorHandleFields.required.passwordConfirmation
            );
          },
          allNumeric: (value) => {
            if (watchPassword !== value) {
              setValue('password', '', { shouldDirty: true });
              setValue('confirmPassword', '', { shouldDirty: true });
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: ToastType.passwordConfirmation,
                  },
                },
              });
            }
            return (
              watchPassword === value ||
              'Confirmation password should be same with password entered before.'
            );
          },
        },
      }}
      value={watchConfirmPassword}
      setValue={(value) => {
        setValue('confirmPassword', value, { shouldDirty: true });
      }}
    />
  );
  const setRelatedBrands = (value) => {
    setValue('brand', value?.value || undefined, { shouldDirty: true })
    if (!value?.value?.pk) {
      dispatch(createAction('storeModel/clearData')())
    }
    setValue('store', undefined, { shouldDirty: true })
  }
  const setRelatedStore = (value) => {
    setValue('store', value || undefined, { shouldDirty: true })
  }
  const relatedBrandSection = (
    <FieldControl
      name="brand"
      rules={{ required: AdminErrorHandleFields.required.brand }}
      render={() => (<>
        <BrandSelectDropdown
          title='Related brand'
          defaultValue={{
            label: watchBrand?.name,
            value: watchBrand
          }}
          onSelectChange={setRelatedBrands}
          addButton={{
            action: () => {
              setShowCreateBrandPrompt(true);
            },
            title: 'Create Now',
            requires: PermissionCodes.addBrand,
            customClass: 'general-section-add-new-brand btn-add-button-common',
          }}
          errors={Object.keys(errors)}
          errorName={'brand'}
          editable={true}
          defaultFilter={{
            isSimpleNode: true
          }}
        />
        <BasePrompt
          show={showCreateBrandPrompt}
          closeAction={() => {
            setShowCreateBrandPrompt(false)
          }}
          rightButton={{
            text: `Go to create brand`,
            action: () => {
              setShowCreateBrandPrompt(false)
              history.push({
                pathname: '/brands/create',
                state: {
                  from: history.location,
                  title: 'Continue to create admin?',
                  content: 'You can continue to create the admin.',
                },
              });
            },
          }}
          leftButton={{
            text: 'Cancel',
            action: () => setShowCreateBrandPrompt(false),
          }}
          title={`Go to create a brand?`}
          description={`You will leave the coupon creation process. After you create a new brand, you can back to this page.`}
        />
      </>
      )}
    />
  )
  const relatedStoreSection = (
    <FieldControl
      name="store"
      rules={{ required: AdminErrorHandleFields.required.store }}
      render={() => (
        <StoreSelectDropdown
          title='Related store'
          selectedStore={{
            displayStoreName: watchStore?.displayStoreName,
            value: watchStore,
          }}
          selectStore={setRelatedStore}
          hideAddButton={false}
          addButtonRequires={PermissionCodes.addStore}
          defaultFilter={{
            brand: watchBrand?.pk || -1,
            isSimpleNode: true,
            isOnlineStore: false
          }}
          errors={Object.keys(errors)}
          errorName={'store'}
        />
      )}
    />
  )
  const convertAdminType = (adminType) => {
    if (!adminType) {
      return '-'
    }
    const displayAdminType = ADMINISTRATOR_TYPES_CONFIG.find((item) => item.value === adminType)
    return displayAdminType?.label || '-'
  };
  return (
    <>
      <SectionTopTitle title="General" />
      {nameSection}
      {id ? (
        <div className="d-flex flex-column">
          <CustomTitleLabel title="Administrator type" />
          <label className="tips-message">
            Once set, cannot be changed.
          </label>
          <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
            {convertAdminType(watchAdminType)}
          </label>
        </div>
      ) :
        adminTypeSection
      }

      {watchAdminType === ADMINISTRATOR_TYPES.MERCHANT_APP_ACCOUNT ? (
        <>
          {relatedBrandSection}
          {relatedStoreSection}
        </>
      ) : null}
      {id ? (
        <ChangePasswordCom changeAction={() => setShowChangePassPrompt(true)} />
      ) : (
        <>
          {passwordSection}
          {confirmPasswordSection}
        </>
      )}

      <ChangePasswordPrompt
        admin={{ username: adminName, pk: id }}
        show={showChangePassPrompt}
        onClose={() => setShowChangePassPrompt(false)}
        onConfirm={() => setShowChangePassPrompt(false)}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  adminName: state.admin.oneAdmin.name,
})
export default connect(mapPropsToState)(GeneralSections);
