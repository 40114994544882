import { ActionType } from 'config/CustomEnums';
import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

import { getInFilterValue } from 'utils';

export const MESSAGE_PAGE_SIZE = 20;

export const getMessages = (
  afterCursor = '',
  rank = true,
  search = '',
  otherSearch = {},
) => {
  let orderBy = '-pk';
  if (!rank) {
    orderBy = 'pk';
  }
  if (otherSearch?.sort) {
    orderBy = otherSearch.sort;
  }

  let moreSearch = '';
  if (otherSearch.message_channel) {
    moreSearch += `, channelsIn: ${getInFilterValue(
      otherSearch.message_channel,
    )}`;
  }
  if (otherSearch.delivery_time) {
    // const deliveryTimeRange = otherSearch.delivery_time.split(',');
    // moreSearch += `, scheduledDateGte: "${deliveryTimeRange[0]}", scheduledDateLte: "${deliveryTimeRange[1]}"`;
    moreSearch += `, scheduledDateEqual: "${otherSearch.delivery_time}"`;
  }
  if (otherSearch.status) {
    moreSearch += `, statusIn: ${getInFilterValue(otherSearch.status)}`;
  }

  let searchType = 'nameIcontains';
  if (otherSearch.isAll) {
    searchType = 'nameIcontainsOrPk';
  }

  const query = `{
    messages(
      first: ${MESSAGE_PAGE_SIZE},
      after: "${afterCursor}",
      orderBy: "${orderBy}",
      ${searchType}: "${search}" ${moreSearch}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          sendingState
          approvalStatus
          channels {
            edges {
              node {
                pk
              }
            }
          }
          scheduledDate
          title
          targetedCustomerGroups {
            edges {
              node {
                pk
                name
              }
            }
          }
          campaign {
            pk
            name
          }
          pushOpenCount
          pushOpenTotalCount
          pushClickCount
          pushClickTotalCount
          emailOpenCount
          emailOpenTotalCount
          emailClickCount
          emailClickTotalCount
          smsClickCount
          smsClickTotalCount
          inboxOpenCount
          inboxOpenTotalCount
          inboxClickCount
          inboxClickTotalCount
          realCreditSpent
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getMessageCredit = (startDate, endDate) => {
  let searchString = 'first:1,';

  if (startDate) {
    searchString += `,scheduledDateGte: "${startDate}"`;
  }
  if (startDate) {
    searchString += `,scheduledDateLte: "${endDate}"`;
  }
  const query = `{
    messages(
      ${searchString}
    ) {
      creditTotalSpent
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getMessage = (id) => {
  const query = `{
    message(id: "${id}") {
      id
      pk
      name
      isDirectMarketing
      channels {
        edges {
          node {
            pk
          }
        }
      }
      scheduledDate
      title
      content
      photo
      emailTitle
      emailContent
      messageTemplate {
        pk
        id
        channels {
            edges {
              node {
                pk
              }
            }
        }
        content
        emailContent
        name
        smsContent
      }
      isStartWithTemplate
      smsContent
      url
      isExclusive
      approvalStatus
      sendToAll
      targetedCustomerGroups {
        edges {
          node {
            pk
            name
          }
        }
      }
      targetedSegments {
        edges {
          node {
            pk
            name
          }
        }
      }
      campaign {
        pk
        name
      }
      sendingState
      translations {
        edges {
          node {
            id
            pk
            language
            title
            content
            photo
            emailTitle
            emailContent
            smsContent

            whatsappTemplateJson
            whatsappTemplateName
            whatsappImage
            whatsappHeaderArgs
            whatsappBodyArgs
          }
        }
      }
      whatsappTemplateJson
      whatsappTemplateName
      whatsappImage
      whatsappHeaderArgs
      whatsappBodyArgs

      pushOpenCount
      pushOpenTotalCount
      pushClickCount
      pushClickTotalCount
      emailOpenCount
      emailOpenTotalCount
      emailClickCount
      emailClickTotalCount
      smsClickCount
      smsClickTotalCount
      inboxOpenCount
      inboxOpenTotalCount
      inboxClickCount
      inboxClickTotalCount
      realCreditSpent
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getMessageTemplate = (
  nameIcontains,
) => {
  let searchString = `orderBy: "-pk"`;
  if (nameIcontains) {
    searchString += `, nameIcontains:"${nameIcontains}"`
  }
  const query = `{
    messageTemplates(
      ${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          pk
          campaign {
            pk
            name
          }
          channels {
            edges {
              node {
                pk
              }
            }
          }
          content
          emailContent
          emailTitle
          name
          photo
          smsContent
          title
          translations {
            edges {
              node {
                id
                pk
                language
                title
                content
                photo
                emailTitle
                emailContent
                smsContent

                whatsappTemplateJson
                whatsappTemplateName
                whatsappImage
                whatsappHeaderArgs
                whatsappBodyArgs
              }
            }
          }
          whatsappTemplateJson
          whatsappTemplateName
          whatsappImage
          whatsappHeaderArgs
          whatsappBodyArgs
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const createMessage = (inputBody) => {
  const query = `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      node {
        id
        pk
        channels {
          edges {
            node {
              pk
            }
          }
        }
        scheduledDate
        title
        content
        photo
        emailTitle
        emailContent
        smsContent
        url
        isExclusive
        sendToAll
        translations {
          edges {
            node {
              pk
              language
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteMessages = (ids) => {
  const query = `
  mutation DeleteMessages($input: DeleteMessagesInput!) {
    deleteMessages(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateMessage = (id) => {
  const query = `
  mutation CopyMessage($input: CopyMessageInput!) {
    copyMessage(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateMessage = (inputBody) => {
  const query = `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      node {
          id
          pk
          scheduledDate
          title
          content
          photo
          url
          isExclusive
          sendingState
          translations {
            edges {
              node {
                pk
                language
              }
            }
          }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const sendTestMessageBeforeCreate = (inputBody) => {
  const query = `
  mutation TestMessageBeforeCreation($input: TestMessageBeforeCreationInput!) {
    testMessageBeforeCreation(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const sendTestMessageBeforeUpdate = (inputBody) => {
  const query = `
  mutation TestMessageBeforeUpdate($input: TestMessageBeforeUpdateInput!) {
    testMessageBeforeUpdate(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createTempMessage = (campaign) => {
  const query = `mutation CreateTempMessage($input: CreateTempMessageInput!) {
    createTempMessage(input: $input) {
      success
      node {
        id
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...campaign,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateApprovalStatus = (input, approvalStatus, actionType) => {
  let mutation = null;
  switch (actionType) {
    case ActionType.publish:
      mutation = 'PublishMessage';
      break;
    case ActionType.unpublish:
      mutation = 'UnpublishMessage';
      break;
    case ActionType.approval:
      mutation = 'PublishMessage';
      break;
    case ActionType.submitForApproval:
      mutation = 'PendingMessage';
      break;
    case ActionType.withdraw:
      mutation = 'WithdrawPendingMessage';
      break;
    default:
      mutation = null;
      break;
  }
  if (!mutation) {
    return;
  }

  const query = `mutation ${mutation}($input: ${mutation}Input!) {
    ${mutation.charAt(0).toLowerCase()}${mutation.slice(1)}(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectPendingForApprovalMessage = (input) => {
  const query = `
  mutation RejectPendingMessage($input: RejectPendingMessageInput!) {
    rejectPendingMessage(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const estimateMessageCredit = (input) => {
  const query = `
  mutation EstimateMessageCredit($input: EstimateMessageCreditInput!) {
    estimateMessageCredit(input: $input) {
      credit
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getWhatsappMessageTemplates = () => {
  const query = `
  query MpowerWhatsappConfigs {
    mpowerWhatsappConfigs {
      edges {
        node {
          id
          name
          approved
          template {
            body
            parameterCount
            footer
            header {
              format
              text
            }
          }
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
