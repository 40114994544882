import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { errorMessage, hasError } from '../base/ErrorFieldMessage';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';

import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import { CREATE_CATEGORY_RESOURCE } from 'containers/merchants/productCategory/CreateCategoryOrSubcategory';
import BaseMultipleSelectorDropDown from 'components/base/BaseMultipleSelectorDropDown';

import { createAction } from 'utils';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const mapPropsToState = (state) => ({
  physicalStoreProductCategories: state.productCategoryList.physicalStoreProductCategories,
  physicalStoreProductSubcategories: state.productCategoryList.physicalStoreProductSubcategories,

  onlineStoreProductCategories: state.productCategoryList.onlineStoreProductCategories,
  onlineStoreProductSubcategories: state.productCategoryList.onlineStoreProductSubcategories,

  allProductCategory: state.productCategoryList.productCategoryList,
  allProductSubcategory: state.productCategoryList.productSubcategoryList,
})

const CreateProductCategoryOrSubcategoryContent = ({
  resource = CREATE_CATEGORY_RESOURCE.productCategory,
  language,
  physicalStoreProductCategories,
  physicalStoreProductSubcategories,
  onlineStoreProductCategories,
  onlineStoreProductSubcategories,
  allProductCategory,
  allProductSubcategory,
}) => {
  const dispach = useDispatch();
  const history = useHistory();
  const { watch, setValue, formState: { errors } } = useFormContext();

  const [showAlertPrompt, setShowAlertPrompt] = useState(
    resource === CREATE_CATEGORY_RESOURCE.pro ? {
      show: false,
      title: "product category",
      currentPageTitle: "product subcategory",
      pathname: "/product_categories/create",
    } : {
        show: false,
        title: "product subcategory",
        currentPageTitle: "product category",
        pathname: "/product_categories/product_subcategories/create",
    }
  )

  const nameField = `translations.${language}.name`;
  const displayOrderField = `displayOrder`
  const assignCategoryField = `productCategories`
  const assignSubcategoryField = `productSubcategories`
  const linkedPhysicalStoreProductSubcategoriesField = `linkedPhysicalStoreProductSubcategories`
  const linkedOnlineStoreCategoryField = `linkedOnlineStoreCollections`
  const linkedOnlineStoreSubcategoriesField = `linkedOnlineStoreTags`
  const selectedCategoriesAndSubSategoriesField = `selectedCategoriesAndSubSategories`

  const watchName = watch(nameField)
  const watchDisplayOrder = watch(displayOrderField)
  const watchAssignCategory = watch(assignCategoryField)
  const watchAssignSubcategory = watch(assignSubcategoryField)
  const watchLinkedPhysicalStoreProductSubcategories = watch(linkedPhysicalStoreProductSubcategoriesField)
  const watchLinkedOnlineStoreCategorys = watch(linkedOnlineStoreCategoryField)
  const watchLinkedOnlineStoreSubcategories = watch(linkedOnlineStoreSubcategoriesField)
  const watchSelectedCategoriesAndSubSategories = watch(selectedCategoriesAndSubSategoriesField)

  const noneTranslationArea = () => {
    if (language !== LanguageConfig.english) {
      return null;
    }
    let generatedCategoriesAndSubcategories = []
    if (resource === CREATE_CATEGORY_RESOURCE.productCategory) {
      generatedCategoriesAndSubcategories = [
        ...physicalStoreProductCategories?.map(item => ({
          ...item,
          realPk: item.pk,
          pk: `physical store product category_${item.pk}`,
          groupKey: {
            pk: "Physical store product category",
            name: "Physical store product category",
          }
        })),
        ...physicalStoreProductSubcategories?.map(item => ({
          ...item,
          realPk: item.pk,
          pk: `physical store product subcategory_${item.pk}`,
          groupKey: {
            pk: "Physical store product subcategory",
            name: "Physical store product subcategory",
          }
        })),
      ]
    }
    return (
      <>
        <CustomTitleWithInput
          title={'Display order (optional)'}
          type={'number'}
          extra={{
            min: 1,
          }}
          setValue={(value) => {
            setValue(displayOrderField, value, { shouldDirty: true, });
          }}
          defaultValue={watchDisplayOrder}
          customClass={`coupon-campaign-section-input `}
          error={{
            id: displayOrderField,
            error: hasError(errors, displayOrderField),
            message: errorMessage(errors, displayOrderField)
          }}
          shortDescription={`Minimum value is 1. The smaller value, the higher priority.`}
          useDefaultValue={false}
        />
        {resource === CREATE_CATEGORY_RESOURCE.productSubcategory ?
          <>
            <BaseMultipleSelectorV2
              title={'Assign a product category'}
              tips={`Select existing product categories (level 2) to place on top of this product subcategory.`}
              data={{
                sourceData: allProductCategory,
                targetData: watchAssignCategory,
                targetChange: (value) => {
                  setValue(assignCategoryField, value, {
                    shouldDirty: true,
                  });
                },
              }}
              addButton={{
                title: 'Add New Product Category',
                action: () => {
                  setShowAlertPrompt({ ...showAlertPrompt , show: true})
                },
              }}
              onFocusFunc={() => {
                // onFocus('subCategory');
              }}
              error={{
                error: hasError(errors, assignCategoryField),
                id: assignCategoryField,
                message: errorMessage(errors, assignCategoryField),
              }}
            />
            <BaseMultipleSelectorV2
              title={`Link to the physical store's product subcategory (level 1) items (optional)`}
              tips={`Select subcategory (level 1) items from physical store to relate with this product subcategory.`}
              data={{
                sourceData: physicalStoreProductSubcategories,
                targetData: watchLinkedPhysicalStoreProductSubcategories,
                targetChange: (value) => {
                  setValue(linkedPhysicalStoreProductSubcategoriesField, value, {
                    shouldDirty: true,
                  });
                },
              }}
              onFocusFunc={() => {
                // onFocus('subCategory');
              }}
              error={{
                error: hasError(errors, linkedPhysicalStoreProductSubcategoriesField),
                id: linkedPhysicalStoreProductSubcategoriesField,
                message: errorMessage(errors, linkedPhysicalStoreProductSubcategoriesField),
              }}
            />
          </>
          :
          <>
            <BaseMultipleSelectorV2
              title={'Assign a product subcategory (optional)'}
              tips={`Select existing product subcategories (level 1) items to place under this product category`}
              data={{
                sourceData: allProductSubcategory,
                targetData: watchAssignSubcategory,
                targetChange: (value) => {
                  console.log('subCategory @65', value);
                  setValue(assignSubcategoryField, value, {
                    shouldDirty: true,
                  });
                },
              }}
              addButton={{
                title: 'Add New Product Subcategory',
                action: () => {
                  setShowAlertPrompt({ ...showAlertPrompt, show: true })
                },
              }}
              onFocusFunc={() => {
                // onFocus('subCategory');
              }}
              error={{
                error: hasError(errors, assignSubcategoryField),
                id: assignSubcategoryField,
                message: errorMessage(errors, assignSubcategoryField),
              }}
            />
            <BaseMultipleSelectorV2
              title={`Link to the physical store's product category (level 2) or product subcategory (level 1) items (optional)`}
              tips={`Select category (level 2) or subcategory (level 1) items from physical store to relate with this product category.`}
              data={{
                sourceData: generatedCategoriesAndSubcategories,
                targetData: watchSelectedCategoriesAndSubSategories,
                targetChange: (value) => {
                  console.log('subCategory @65', value);
                  setValue(selectedCategoriesAndSubSategoriesField, value, {
                    shouldDirty: true,
                  });
                },
              }}
              groupKey={'groupKey'}
              canNoChildren={true}
              onFocusFunc={() => {
                // onFocus('subCategory');
              }}
            />
          </>
        }
        {resource === CREATE_CATEGORY_RESOURCE.productCategory &&
          <BaseMultipleSelectorDropDown
            filterTitle={`Link to the online store's product category (level 2) items (optional)`}
            customSummaryText={'product category (level 2) items'}
            placeholder='Search by name'
            source={onlineStoreProductCategories}
            value={watchLinkedOnlineStoreCategorys}
            onChange={(value) => {
              setValue(linkedOnlineStoreCategoryField, value, { shouldDirty: true });
            }}
            filterAction={(search) => {
              dispach(createAction("productCategoryList/getAllOnlineStoreCollections")({
                search
              }))
            }}
            customItem={(item) => item?.title || "-"}
          />
        }
        <BaseMultipleSelectorDropDown
          filterTitle={`Link to the online store's product subcategory (level 1) items (optional)`}
          customSummaryText={'product subcategory (level 1) items'}
          placeholder='Search by name'
          source={onlineStoreProductSubcategories}
          value={watchLinkedOnlineStoreSubcategories}
          onChange={(value) => {
            setValue(linkedOnlineStoreSubcategoriesField, value, { shouldDirty: true });
          }}
          filterAction={(search)=>{
            dispach(createAction("productCategoryList/getAllOnlineStoreTags")({
              search
            }))
          }}
          customItem={(item) => item.name || "-"}
        />
      </>
    );
  };

  return (
    <>
      <div className="first-section-title">
        {i18n.t('product_categroy', { locale: language })}
      </div>
      <CustomTitleWithInput
        title={i18n.t('name', { locale: language })}
        error={{
          id: nameField,
          error: hasError(errors, nameField),
          message: errorMessage(errors, nameField)
        }}
        type={'text'}
        customClass={`text-input-field date-picker `}
        defaultValue={watchName}
        setValue={(value) => {
          setValue(nameField, value, { shouldDirty: true, });
        }}
        useDefaultValue={false}
      />
      {noneTranslationArea()}
      <BasePrompt
        show={showAlertPrompt.show}
        closeAction={() =>
          setShowAlertPrompt({ ...showAlertPrompt, show: false })
        }
        rightButton={{
          text: `Go to create ${showAlertPrompt.title}`,
          action: () => {
            history.push({
              pathname: showAlertPrompt.pathname,
              state: {
                from: history.location,
                title: `Continue to create ${showAlertPrompt.currentPageTitle}?`,
                content: `You can continue to create the ${showAlertPrompt.currentPageTitle}.`,
              },
            });
          },
        }}
        title={`Go to create a ${showAlertPrompt.title}?`}
        description={`You will leave the store creation process. After you create a new ${showAlertPrompt.title}, you can back to this page.`}
      />
    </>
  );
}

export default connect(mapPropsToState)(CreateProductCategoryOrSubcategoryContent);