import React, { useEffect, useState } from 'react';
import './Login.scss';
import { Button } from 'react-bootstrap';
import { withResizeDetector } from 'react-resize-detector';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Image, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import LoginLeftBackground from '../../assets/images/login_bg.png';
import LoginLeftIcon from '../../assets/images/login_icon.png';
import LoginLogo from '../../assets/images/login_logo.png';
import { createAction, isNumber } from '../../utils';
import {
  CustomTitleLabel,
  CustomTitleWithRadio,
} from '../../components/earning/CustomBaseComponments';
import ErrorFieldMessage from '../../components/base/ErrorFieldMessage';
import { AdminErrorHandleFields } from '../../containers/admin/admins/AdminErrorHandleFields';
import { ToastType } from '../../models/NavBarModel';

const LEFT_ICON_MAX_WIDTH = 604;
const LEFT_ICON_MAX_LEFT_POSITION = 142;
const FORM_MAX_MARGIN_LEFT = 566;
const FORM_MAX_PADDING_LEFT = 100;
const MAX_DYNAMIC_SCREEN_WIDTH = 1280;
const MIN_DYNAMIC_SCREEN_WIDTH = 750;
const CHANGE_FIX = 1.25;
const TIMER_LENGTH = 59;

const LoginMain = ({ width, height }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isLogin,
    loginError,
    userExist,
    haveLoginMethod,
    changePasswordError,
  } = useSelector((state) => ({
    isLogin: state.users.isLogin,
    loginError: state.users.loginError,
    userExist: state.users.userExist,
    haveLoginMethod: state.users.haveLoginMethod,
    changePasswordError: state.admin.changePasswordError,
  }));
  const [sendLoading, setSendLoading] = useState(false);
  const [sendButtonLoading, setSendButtonLoading] = useState(false);
  const [loadingSeconds, setLoadingSeconds] = useState(TIMER_LENGTH);

  const [resetPasswordSendLoading, setResetPasswordSendLoading] = useState(false);
  const [resetPasswordSendButtonLoading, setResetPasswordSendButtonLoading] = useState(false);
  const [resetPasswordLoadingSeconds, setResetPasswordLoadingSeconds] = useState(TIMER_LENGTH);

  const [formData, setFormData] = useState({});
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [errorFields, setErrorFields] = useState({});

  const loginFillInfo =
    formData.username && formData.password && formData.method && formData.code && userExist;
  const resetPasswordFillInfo =
    formData.username && formData.newPassword && formData.newPasswordConfirmation && formData.code && userExist;

  const login = (event) => {
    event.preventDefault();
    dispatch(
      createAction('users/login')({
        username: formData.username,
        password: formData.password,
        method: formData.method,
        code: formData.code
      }),
    );
  };

  const resetPassword = (event) => {
    event.preventDefault();
    const { newPassword, newPasswordConfirmation } = formData
    setErrorFields({})
    if (!newPassword) {
      setErrorFields({ newPassword: AdminErrorHandleFields?.required?.password })
    } else if (newPassword.length < 8) {
      setErrorFields({ newPassword: AdminErrorHandleFields?.others?.password?.length })
    } else if (isNumber(newPassword)) {
      setErrorFields({ newPassword: AdminErrorHandleFields?.others?.password?.numeric })
    } else if (newPassword !== newPasswordConfirmation) {
      setErrorFields({ newPasswordConfirmation: ToastType.passwordConfirmation })
    } else if (userExist) {
      dispatch(
        createAction('admin/changePassword')({
          data: {
            username: formData.username,
            password1: formData.newPassword,
            password2: formData.newPasswordConfirmation,
            method: 'RESET_PASSWORD',
            code: formData.code,
            needLogin: true
          },
        }),
      );
    }
  }

  const changeIsResetPassword = () => {
    const isUserExist = userExist
    dispatch(
      createAction('users/resetUser')(),
    );
    dispatch({
      type: 'users/updateState',
      payload: {
        'userExist': isUserExist,
      },
    });
    dispatch(createAction('admin/updateState')({
      changePasswordError: {}
    }))
    setIsResetPassword(!isResetPassword)
    setFormData({ ...formData, code: '' })
  }

  useEffect(() => {
    if (isLogin) {
      history.push('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  let leftIconWidth = LEFT_ICON_MAX_WIDTH;
  let leftIconLeft = LEFT_ICON_MAX_LEFT_POSITION;
  let formLeftMargin = FORM_MAX_MARGIN_LEFT;
  let formLeftPadding = FORM_MAX_PADDING_LEFT;
  if (width < MAX_DYNAMIC_SCREEN_WIDTH && width >= MIN_DYNAMIC_SCREEN_WIDTH) {
    const changePercentage =
      (MAX_DYNAMIC_SCREEN_WIDTH - width) / MAX_DYNAMIC_SCREEN_WIDTH;
    leftIconWidth = (1 - CHANGE_FIX * changePercentage) * LEFT_ICON_MAX_WIDTH;
    leftIconLeft =
      (1 - CHANGE_FIX * changePercentage) * LEFT_ICON_MAX_LEFT_POSITION;
    formLeftMargin = (width / MAX_DYNAMIC_SCREEN_WIDTH) * FORM_MAX_MARGIN_LEFT;
    formLeftPadding =
      FORM_MAX_PADDING_LEFT -
      (((MAX_DYNAMIC_SCREEN_WIDTH - width) * CHANGE_FIX) /
        (MAX_DYNAMIC_SCREEN_WIDTH - MIN_DYNAMIC_SCREEN_WIDTH)) *
      FORM_MAX_PADDING_LEFT;
  }
  const hideLeft = width < MIN_DYNAMIC_SCREEN_WIDTH;
  if (hideLeft) {
    formLeftMargin = 0;
    formLeftPadding = 0;
  }
  const getFormItem = (title, name, type, tips) => {
    return (
      <div className="login-form-item">
        <label className="login-form-label">{title}</label>
        {tips ? <label className="tips-message">{tips}</label> : null}
        <input
          value={formData?.[name] || ''}
          name={name}
          type={type}
          className={`login-form-input ${loginError?.credentialsError ||
            (!userExist && name === 'username')
            ? 'error-field' : ''}`}
          onChange={(e) => {
            setFormData({ ...formData, [name]: e.target.value });
            if (loginError?.credentialsError) {
              const loginError = { credentialsError: false };
              dispatch({
                type: 'users/updateState',
                payload: {
                  loginError,
                },
              });
            }
          }}
          {...(name === 'username'
            ? {
              onBlur: (e) => {
                formData.username &&
                  dispatch({
                    type: 'users/checkAdminLoginValid',
                    payload: { username: formData.username },
                  });
              },
            }
            : null)}
        />
        {errorFields?.[name] ?
          <label className={`login-error error-field`}>
            {errorFields?.[name]}
          </label>
          : null
        }
        {!isResetPassword && loginError?.credentialsError ? (
          <label className={`login-error error-field`}>
            We cannot recognize your login / password combination.
          </label>
        ) : null}
        {!userExist && name === 'username' ? (
          <label className={`login-error error-field`}>Account does not exist</label>
        ) : null}
      </div>
    );
  };

  const handleSendCode = () => {
    isResetPassword ?
      setResetPasswordSendButtonLoading(true) : setSendButtonLoading(true);
    const { username, password, method } = formData;
    dispatch({
      type: 'users/sendCode',
      payload: {
        data: isResetPassword ? {
          username,
          method: 'RESET_PASSWORD',
        } : {
          username,
          password,
          method,
        },
        handleSuccess: () => {
          isResetPassword ? setResetPasswordSendLoading(true) :
            setSendLoading(true);
        },
        afterAction: () => {
          isResetPassword ? setResetPasswordSendButtonLoading(false) :
            setSendButtonLoading(false);
        },
      },
    });
  }

  useEffect(() => {
    const loadingInterval =
      sendLoading &&
      loadingSeconds > 0 &&
      setInterval(() => {
        if (loadingSeconds > 1) {
          setLoadingSeconds(loadingSeconds - 1);
        } else {
          setSendLoading(false);
          setLoadingSeconds(TIMER_LENGTH);
        }
      }, 1000);
    return () => {
      clearInterval(loadingInterval);
    };
  }, [sendLoading, loadingSeconds]);

  useEffect(() => {
    const loadingInterval =
      resetPasswordSendLoading &&
      resetPasswordLoadingSeconds > 0 &&
      setInterval(() => {
        if (resetPasswordLoadingSeconds > 1) {
          setResetPasswordLoadingSeconds(resetPasswordLoadingSeconds - 1);
        } else {
          setResetPasswordSendLoading(false);
          setResetPasswordLoadingSeconds(TIMER_LENGTH);
        }
      }, 1000);
    return () => {
      clearInterval(loadingInterval);
    };
  }, [resetPasswordSendLoading, resetPasswordLoadingSeconds]);

  useEffect(() => {
    if (haveLoginMethod?.length === 1) {
      setFormData({ ...formData, method: haveLoginMethod[0] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveLoginMethod]);

  return (
    <div className="login">
      {hideLeft ? null : (
        <>
          <Image className="left-bg" src={LoginLeftBackground} />
          <Image
            className="left-icon"
            style={{
              width: leftIconWidth,
              left: leftIconLeft,
            }}
            src={LoginLeftIcon}
          />
        </>
      )}
      <Form
        style={{ marginLeft: formLeftMargin, paddingLeft: formLeftPadding }}
        className="login-form"
        autoComplete="off"
        noValidate
        onSubmit={isResetPassword ? resetPassword : login}
      >
        
        {isResetPassword ? <div className="reset-password-header">{'RESET PASSWORD'}</div> :
          <Image className="login-form-logo" src={LoginLogo} />
        }
        {isResetPassword ? <>
          {getFormItem('Account', 'username', 'text')}
          {getFormItem('New password', 'newPassword', 'password', 'Password at least 8 characters, can’t be entirely numeric.')}
          {getFormItem('Password confirmation', 'newPasswordConfirmation', 'password', 'Enter the same password as before, for verification')}
        </> : <>
          {getFormItem('Account', 'username', 'text')}
          {getFormItem('Password', 'password', 'password')}
          {haveLoginMethod?.length > 1 ? (
            <div className="verify-channel-container">
              <CustomTitleWithRadio
                defaultValue={formData?.method || ''}
                title={'Verification channel'}
                customTitleClass={'verify-channel-title'}
                options={[
                  { label: 'SMS', value: 'MOBILE_PHONE_NUMBER' },
                  { label: 'Email', value: 'EMAIL_ADDRESS' },
                ]}
                setValue={(value) => {
                  setFormData({ ...formData, method: value });
                }}
              />
            </div>
          ) : null}
        </>}


        <div className="otp-code-container">
          <CustomTitleLabel
            className={'otp-code-title'}
            title={'Verification code'}
          />
          <div className="d-flex">
            <input
              value={formData?.code}
              type={'number'}
              name={'code'}
              className={`custom-markdown-area-title ${loginError?.code?.error ? 'error-field' : ''
                }  opt-code-input`}
              onChange={(e) => {
                if (loginError?.code) {
                  const loginError = { code: {} };
                  dispatch({
                    type: 'users/updateState',
                    payload: {
                      loginError,
                    },
                  });
                }
                setFormData({ ...formData, code: e.target.value });
              }}
              onKeyUp={(e) => {
                if (!isResetPassword && e.code === 'Enter' && loginFillInfo && userExist) {
                  login(e);
                } else if (isResetPassword && e.code === 'Enter' && resetPasswordFillInfo && userExist) {
                  resetPassword(e)
                }
              }}
            />
            <Button
              className="btn-further otp-code-send-button"
              onClick={() => {
                handleSendCode();
              }}
              disabled={isResetPassword ? (
                resetPasswordSendLoading ||
                !formData.username ||
                !formData.newPassword ||
                !formData.newPasswordConfirmation ||
                !userExist ||
                resetPasswordSendButtonLoading
              ) : (
                sendLoading ||
                !formData.username ||
                !formData.password ||
                !formData.method ||
                !userExist ||
                sendButtonLoading
              )}
            >
              {!isResetPassword && sendLoading ?
                `${loadingSeconds}s` :
                isResetPassword && resetPasswordSendLoading ?
                  `${resetPasswordLoadingSeconds}s` : 'Send code'
              }
            </Button>
          </div>
          {loginError?.code?.error || changePasswordError?.code?.error ? (
            <ErrorFieldMessage
              id={isResetPassword ? changePasswordError?.code?.id : loginError?.code?.id}
              error={isResetPassword ? changePasswordError?.code : loginError?.code}
              message={isResetPassword ? changePasswordError?.code?.message : loginError?.code?.message}
            />
          ) : null}
        </div>
        <Button
          type="submit"
          className="btn-further login-form-button"
          disabled={(isResetPassword ? !resetPasswordFillInfo : !loginFillInfo)}
        >
          {isResetPassword ? 'Confirm' : 'Login'}
        </Button>
        <Button
          type="button"
          variant={'link'}
          className="login-form-button btn-link"
          onClick={() => changeIsResetPassword()}
        >
          {isResetPassword ? 'Back to Login' : 'Forgot password'}
        </Button>
      </Form>
    </div>
  );
};

const LoginWithSize = withResizeDetector(LoginMain);

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.users.isLogin);
  if (history?.location?.state?.isLogout) {
    dispatch({ type: 'users/logout' });
    return <LoginWithSize />;
  }
  return isLogin ? <Redirect to="/" /> : <LoginWithSize />;
}

export default withRouter(Login);
