import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { CREATE_CATEGORY_RESOURCE } from './CreateCategoryOrSubcategory';
import { productCategoryValidate, productSubcategoryValidate } from './CreatCategoryValidate';

import CreateProductCategoryOrSubcategoryContent from 'components/productCategory/CreateProductCategoryOrSubcategoryContent';
import { createAction } from 'utils';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import { PRODUCT_LIST_TAB } from './ProductCategoryList';

const mapPropsToState = (state) => ({
  languages: state.language.allList,
})

const CreateCategoryOrSubcategoryStepOne = ({
  resource = CREATE_CATEGORY_RESOURCE.productCategory,
  setShowCompleteProcess,
  languages,
}) => {
  const queryString = require('query-string');
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setError, clearErrors, getValues } = useFormContext();

  useEffect(() => {
    dispatch(createAction('language/getList')({
      isSelectorLoad: true,
    }))
    if (resource === CREATE_CATEGORY_RESOURCE.productCategory) {
      dispatch(createAction('productCategoryList/getPagedProductSubcategories')())
      dispatch(createAction('productCategoryList/getAllPhysicalStoreCategory')())
      dispatch(createAction('productCategoryList/getAllOnlineStoreCollections')())
    } else if (resource === CREATE_CATEGORY_RESOURCE.productSubcategory) {
      dispatch(createAction('productCategoryList/getPagedProductCategories')())
    }
    dispatch(createAction('productCategoryList/getAllPhysicalStoreSubcategory')())
    dispatch(createAction('productCategoryList/getAllOnlineStoreTags')())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.id]);

  const getLanguageTab = (language) => {
    return [<CreateProductCategoryOrSubcategoryContent resource={resource} language={language} />];
  };

  const languageTabConfig = {
    containers: languages?.map((item) => ({
      container: getLanguageTab(item.code),
      key: item.code,
      title: item.sourceName,
    })),
  };

  const goToCategoryList = () => {
    dispatch(createAction(
      resource === CREATE_CATEGORY_RESOURCE.productSubcategory
        ? 'createProductCategory/saveOrRemoveProductSubcategoryFromCookie'
        : 'createProductCategory/saveOrRemoveProductCategoryFromCookie'
    )({
      save: false,
    }))
    const hash = resource === CREATE_CATEGORY_RESOURCE.productSubcategory
      ? PRODUCT_LIST_TAB.productSubcategories : PRODUCT_LIST_TAB.productCategories;
    history.push({
      pathname: '/product_categories',
      hash,
      search: queryString.stringify({
        page: 1,
        rank: true,
        search: '',
      }),
    });
  };

  const goToCategoryDetail = () => {
    if (resource === CREATE_CATEGORY_RESOURCE.productSubcategory) {
      dispatch(createAction('createProductCategory/saveOrRemoveProductSubcategoryFromCookie')({
        save: false,
      }))
      history.push({
        pathname: `/product_categories/product_subcategories/${params.id}/`,
      });
      return;
    }
    if (resource === CREATE_CATEGORY_RESOURCE.productCategory) {
      dispatch(createAction('createProductCategory/saveOrRemoveProductCategoryFromCookie')({
        save: false,
      }))
      history.push({
        pathname: `/product_categories/${params.id}/`,
      });
      return;
    }
  };

  const save = function () {
    const validate = resource === CREATE_CATEGORY_RESOURCE.productSubcategory
      ? productSubcategoryValidate : productCategoryValidate
    let isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
    });
    if (!isValid) {
      return;
    }
    let createActionType = resource === CREATE_CATEGORY_RESOURCE.productSubcategory
      ? 'createProductCategory/createOrUpdateProductSubcategory'
      : 'createProductCategory/createOrUpdateProductCategory'
    if (params.id) {
      dispatch({
        type: createActionType,
        payload: {
          id: params.id,
          values: getValues(),
          afterActions: goToCategoryList,
        }
      });
    } else {
      dispatch({
        type: createActionType,
        payload: {
          values: getValues(),
          afterActions: () => setShowCompleteProcess(true),
        }
      });
    }
  };

  return (
    <>
      <ContentSections
        languageTabContent={languageTabConfig}
        hidePreview
      />

      <SaveAndBackButtons
        backAction={() => {
          if (params.id) {
            goToCategoryList();
          } else {
            goToCategoryDetail();
          }
        }}
        saveText={params.id ? 'Update' : 'Save'}
        saveAction={() => {
          save();
        }}
      />
    </>
  );
}


export default connect(mapPropsToState)(CreateCategoryOrSubcategoryStepOne);
