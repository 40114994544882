import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

import { getInFilterValue } from 'utils';

const CONTENT = `
id
pk
type
name
generalPurchaseTypeMinimumSpending
generalPurchaseTypeMaximumSpending
generalPurchaseTypeLimitedToStores {
  edges {
    node {
      id
      pk
      name
      visibleInFrontEnd
    }
  }
}
generalPurchaseTypeEligibleNumberOfDaysSincePurchase
generalPurchaseTypeOnlyForBirthMonth
generalPurchaseTypeWillExcludeDeliveryCost
generalPurchaseTypeWillExcludeOtherCharges
generalPurchaseTypeExcludedProducts
generalPurchaseTypeOverallLimit
generalPurchaseTypePerHeadLimit
generalPurchaseTypePeriodicLimit
generalPurchaseTypePeriodicLimitEffectiveNumberOfDays
generalPurchaseTypePerHeadPeriodicLimit
generalPurchaseTypePerHeadPeriodicLimitEffectiveNumberOfDays
generalPurchaseTypeRewardType
generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
generalPurchaseTypePointsRewardTypeX
generalPurchaseTypePointsRewardTypeTpeX
generalPurchaseTypePointsRewardTypeTpePerXDollarsSpent
generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent
generalPurchaseTypeStampRewardTypeX
generalPurchaseTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
}
generalPurchaseTypeCouponRewardTypeQuantity
generalPurchaseTypeLimitedToSkus {
  edges {
    node {
      id
      pk
      skuId
      sku
      categoryName
      categoryId
    }
  }
}

memberReferralTypeBeneficiary
memberReferralTypeLimit
memberReferralTypeRewardType
memberReferralTypePointsRewardTypePoints
memberReferralTypePointsRewardTypeTpe
memberReferralTypeStampRewardTypeQuantity
memberReferralTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
}
memberReferralTypeCouponRewardTypeQuantity
newMemberTypeRewardType
newMemberTypePointsRewardTypePoints
newMemberTypePointsRewardTypeTpe
newMemberTypeStampRewardTypeQuantity
newMemberTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
}
newMemberTypeCouponRewardTypeQuantity
birthdayTypeRewardType
birthdayTypePointsRewardTypePoints
birthdayTypePointsRewardTypeTpe
birthdayTypeStampRewardTypeQuantity
birthdayTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
}
birthdayTypeCouponRewardTypeQuantity
qrCodeScanningTypeQrCodes
qrCodeScanningTypeOverallLimit
qrCodeScanningTypePerHeadLimit
qrCodeScanningTypePeriodicLimit
qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays
qrCodeScanningTypePerHeadPeriodicLimit
qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays
qrCodeScanningTypeRewardType
qrCodeScanningTypePointsRewardTypePoints
qrCodeScanningTypePointsRewardTypeTpe
qrCodeScanningTypeStampRewardTypeQuantity
qrCodeScanningTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
}
qrCodeScanningTypeCouponRewardTypeQuantity
gpsCheckInTypeLatitude
gpsCheckInTypeLongitude
gpsCheckInTypeRadiusInMeter
gpsCheckInTypeOverallLimit
gpsCheckInTypePerHeadLimit
gpsCheckInTypePeriodicLimit
gpsCheckInTypePeriodicLimitEffectiveNumberOfDays
gpsCheckInTypePerHeadPeriodicLimit
gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays
gpsCheckInTypeRewardType
gpsCheckInTypePointsRewardTypePoints
gpsCheckInTypePointsRewardTypeTpe
gpsCheckInTypeStampRewardTypeQuantity
gpsCheckInTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
}
gpsCheckInTypeCouponRewardTypeQuantity
fillingFormTypeForm {
  pk
  id
  name
}
fillingFormTypeDefinitionType
fillingFormTypeOverallLimit
fillingFormTypePerHeadLimit
fillingFormTypePeriodicLimit
fillingFormTypePeriodicLimitEffectiveNumberOfDays
fillingFormTypePerHeadPeriodicLimit
fillingFormTypePerHeadPeriodicLimitEffectiveNumberOfDays
fillingFormTypeRewardType
fillingFormTypePointsRewardTypePoints
fillingFormTypePointsRewardTypeTpe
fillingFormTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
}
fillingFormTypeCouponRewardTypeQuantity
levelUpgradeTypeLevelGoal{
  pk
  id
  levelName
}
levelUpgradeTypePerHeadPeriodicLimit
levelUpgradeTypePerHeadPeriodicLimitEffectiveNumberOfDays
levelUpgradeTypeRewardType
levelUpgradeTypePointsRewardTypePoints
levelUpgradeTypePointsRewardTypeTpe
levelUpgradeTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
}
levelUpgradeTypeCouponRewardTypeQuantity
levelUpgradeTypeStampRewardTypeQuantity
creationDate
lastModifiedDate
linkedCampaignType
campaigns {
  edges {
    node {
      id
      pk
      name
    }
  }
}
stampTaskRelation{
  edges{
    node{
      stampCampaign{
        pk
        name
      }
    }
  }
}
startDate
endDate
blackoutPeriod {
  edges{
    node {
      pk
      startDate
      endDate
    }
  }
}
blackoutWeekday
isExclusive
targetedCustomerGroups {
  edges {
    node {
      pk
      name
    }
  }
}
targetedSegments {
  edges {
    node {
      pk
      name
    }
  }
}
targetedLevels {
  edges {
    node {
      pk
      levelName
    }
  }
}
`;

export const getEarningRulesWithType = (
  afterCursor,
  rank,
  type,
  nameContains = '',
  otherSearch,
  linkedCampaignType,
) => {
  let order = rank ? '-pk' : 'pk';
  if (otherSearch?.sort) {
    order = otherSearch.sort;
  }

  let moreSearch = '';
  if (otherSearch?.rewardType) {
    moreSearch += `, rewardTypeIn: ${getInFilterValue(otherSearch.rewardType)}`;
  }
  if (
    otherSearch?.targetCustomer &&
    otherSearch?.[otherSearch.targetCustomer]
  ) {
    moreSearch += `, ${otherSearch.targetCustomer}: ${getInFilterValue(
      otherSearch[otherSearch.targetCustomer],
    )}`;
  }
  if (otherSearch?.typeIn?.length > 0) {
    moreSearch += `, typeIn: ${getInFilterValue(otherSearch?.typeIn)}`;
  }
  if (otherSearch?.customerId) {
    moreSearch += `, customerId: ${otherSearch?.customerId}`;
  }

  const query = `{
    earningRules(first: 20, after: "${afterCursor}", nameIcontains: "${nameContains}", type: "${
    type === 'all' || type === undefined ? '' : type
  } ", orderBy: "${order}"${moreSearch}, ${
    linkedCampaignType ? 'linkedCampaignType: "' + linkedCampaignType + '"' : ''
  }){
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${CONTENT}
          numberOfCustomersVisible
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteEarningRules = (ids) => {
  const query = `mutation DeleteEarningRules($input: DeleteEarningRulesInput!) {
    deleteEarningRules(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ids: ids,
    },
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneEarningRule = (id) => {
  const query = `
  {
    earningRule(id: "${id}") {
      ${CONTENT}
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      translations {
        edges {
          node {
            pk
            language
            name
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateEarningRule = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}EarningRule($input: ${action}EarningRuleInput!) {
    ${action.toLowerCase()}EarningRule(input: $input) {
      success
      node {
        id
        pk
        type
        name
        instructionSectionTitle
        instructionSectionContent
        detailSectionTitle
        detailSectionContent
        generalPurchaseTypeMinimumSpending
        generalPurchaseTypeMaximumSpending
        generalPurchaseTypeLimitedToStores {
          edges {
            node {
              id
            }
          }
        }
        generalPurchaseTypeEligibleNumberOfDaysSincePurchase
        generalPurchaseTypeOnlyForBirthMonth
        generalPurchaseTypeWillExcludeDeliveryCost
        generalPurchaseTypeWillExcludeOtherCharges
        generalPurchaseTypeExcludedProducts
        generalPurchaseTypeRewardType
        generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
        generalPurchaseTypePointsRewardTypeX
        generalPurchaseTypePointsRewardTypeTpePerXDollarsSpent
        generalPurchaseTypePointsRewardTypeTpeX
        generalPurchaseTypeCouponRewardTypeCouponTemplate {
          id
        }
        generalPurchaseTypeCouponRewardTypeQuantity
        generalPurchaseTypeOverallLimit
        generalPurchaseTypePerHeadLimit
        generalPurchaseTypePeriodicLimit
        generalPurchaseTypePeriodicLimitEffectiveNumberOfDays
        generalPurchaseTypePerHeadPeriodicLimit
        generalPurchaseTypePerHeadPeriodicLimitEffectiveNumberOfDays
        memberReferralTypeBeneficiary
        memberReferralTypeLimit
        memberReferralTypeRewardType
        memberReferralTypePointsRewardTypePoints
        memberReferralTypePointsRewardTypeTpe
        memberReferralTypeCouponRewardTypeCouponTemplate {
          id
        }
        memberReferralTypeCouponRewardTypeQuantity
        newMemberTypeRewardType
        newMemberTypePointsRewardTypePoints
        newMemberTypePointsRewardTypeTpe
        newMemberTypeCouponRewardTypeCouponTemplate {
          id
        }
        newMemberTypeCouponRewardTypeQuantity
        birthdayTypeRewardType
        birthdayTypePointsRewardTypePoints
        birthdayTypePointsRewardTypeTpe
        birthdayTypeCouponRewardTypeCouponTemplate {
          id
        }
        birthdayTypeCouponRewardTypeQuantity
        qrCodeScanningTypeQrCodes
        qrCodeScanningTypeOverallLimit
        qrCodeScanningTypePerHeadLimit
        qrCodeScanningTypePeriodicLimit
        qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays
        qrCodeScanningTypePerHeadPeriodicLimit
        qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays
        qrCodeScanningTypeRewardType
        qrCodeScanningTypePointsRewardTypePoints
        qrCodeScanningTypePointsRewardTypeTpe
        qrCodeScanningTypeCouponRewardTypeCouponTemplate {
          id
        }
        qrCodeScanningTypeCouponRewardTypeQuantity
        gpsCheckInTypeLatitude
        gpsCheckInTypeLongitude
        gpsCheckInTypeRadiusInMeter
        gpsCheckInTypeOverallLimit
        gpsCheckInTypePerHeadLimit
        gpsCheckInTypePeriodicLimit
        gpsCheckInTypePeriodicLimitEffectiveNumberOfDays
        gpsCheckInTypePerHeadPeriodicLimit
        gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays
        gpsCheckInTypeRewardType
        gpsCheckInTypePointsRewardTypePoints
        gpsCheckInTypePointsRewardTypeTpe
        gpsCheckInTypeCouponRewardTypeCouponTemplate {
          id
        }
        gpsCheckInTypeCouponRewardTypeQuantity
        fillingFormTypeForm {
          id
        }
        fillingFormTypeDefinitionType
        fillingFormTypeOverallLimit
        fillingFormTypePerHeadLimit
        fillingFormTypePeriodicLimit
        fillingFormTypePeriodicLimitEffectiveNumberOfDays
        fillingFormTypePerHeadPeriodicLimit
        fillingFormTypePerHeadPeriodicLimitEffectiveNumberOfDays
        fillingFormTypeRewardType
        fillingFormTypePointsRewardTypePoints
        fillingFormTypePointsRewardTypeTpe
        fillingFormTypeCouponRewardTypeCouponTemplate {
          id
        }
        birthdayTypeStampRewardTypeQuantity
        generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent
        generalPurchaseTypeStampRewardTypeX
        gpsCheckInTypeStampRewardTypeQuantity
        fillingFormTypeCouponRewardTypeQuantity
        linkedCampaignType
        memberReferralTypeStampRewardTypeQuantity
        newMemberTypeStampRewardTypeQuantity
        qrCodeScanningTypeStampRewardTypeQuantity
        startDate
        endDate
        isExclusive
        targetedCustomerGroups {
          edges {
            node {
              pk
              name
            }
          }
        }
        targetedSegments {
          edges {
            node {
              pk
              name
            }
          }
        }
        targetedLevels {
          edges {
            node {
              pk
              levelName
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getEarningRuleList = (afterCursor = '', search) => {
  let moreSearch = '';
  if (search?.linkedCampaignType) {
    moreSearch =
      moreSearch + `linkedCampaignType:"${search.linkedCampaignType}"`;
  }
  const query = `{
    earningRules(${moreSearch}){
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${CONTENT}
        }
      }
    }
  }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getWebhookAuthenticatedKey = (earningRuleId) => {
  const query = `{
    authenticatedKeys(first: 20, earningRuleId: ${earningRuleId}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          key
          isCopied
        }
      }
    }
  }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateWebhookAuthenticatedKey = (values) => {
  const action = values.id ? 'Update' : 'Create';
  const query = `mutation ${action}AuthenticatedKey($input: ${action}AuthenticatedKeyInput!) {
    ${action.toLowerCase()}AuthenticatedKey(input: $input) {
      success
      node {
        id
        pk
        key
        isCopied
      }
    }
  }`;
  const variables = {
    input: values,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
