import React from 'react';
import './SideMenuNav.scss';
import CollapseNav from './CollapseNav';
import { Accordion, Image } from 'react-bootstrap';
import menuLogo from '../../assets/images/side_menu_logo.png';
import { tabList } from '../../config/NavBarConfig';

function SideMenuNav() {
  return (
    <div>
      <Image src={menuLogo} className="side-bar-logo" />
      <Accordion className="side-menu-nav-background">
        {tabList.map((tab, index) => (
          <CollapseNav tab={tab} key={`${tab.name}-${index}`} />
        ))}
      </Accordion>
    </div>
  );
}

export default SideMenuNav;
